import React, { useContext } from "react";
import { outLinks } from "../../../../routes/routes";
import { BigglzContext } from "../../../../utils/contextProvider";
import { ENG, KOR } from "../../../../layout/layout";

export const AppInfo = () => {
  const { myLanguage } = useContext(BigglzContext);

  return (
    <>
      <div className="persona-box download" style={{
        'backgroundImage': `url('/images/sub/AIbusuness_img_2.png')`,
        'backgroundPosition': 'center',
        'backgroundSize': 'cover',
        'backgroundRepeat': 'no-repeat'
      }}>
        <>
          {
            myLanguage === KOR ? (
              <>
                <p>지금 바로 다운받아보세요.</p>
                <div className="btn-area">
                  <a href={outLinks.hiingAppStore} target='_blank' rel="noopener noreferrer" className="btn apple">
                    <img src="/images/common/btn_appstore.png" alt="app store 에서 다운로드 하기" />
                  </a>
                  <a href={outLinks.hiingGoogleStore} target='_blank' rel="noopener noreferrer" className="btn google">
                    <img src="/images/common/btn_googleplay.png" alt="google play에서 다운로드 하기" />
                  </a>
                </div>
              </>
            ) : myLanguage === ENG ? (
              <>
                <p>Download it right now.</p>
                <div className="btn-area">
                  <a href={outLinks.hiingAppStore} target='_blank' rel="noopener noreferrer" className="btn apple">
                    <img src="/images/eng/eng_btn_appstore.png" alt="Download on the App Store" />
                  </a>
                  <a href={outLinks.hiingGoogleStore} target='_blank' rel="noopener noreferrer" className="btn google">
                    <img src="/images/eng/eng_btn_googleplay.png" alt="Get it on Google Play" />
                  </a>
                </div>
              </>
            ) : null
          }
        </>
      </div>
      <div className="persona-box intro">
        <div className="intro-area">
          <p>
            {
              myLanguage === KOR ? (
                <>
                  <div className="tit">
                    <span>
                      <strong>Heart-Warming</strong>
                    </span>
                    <span>AI friends are waiting for you.</span>
                    <div className="tags">
                      <i>#개인화메세지</i>
                      <i>#나만의 단짝친구</i>
                      <i>#다양한 페르소나</i>
                    </div>
                  </div>
                  <div className="image"><img src="/images/sub/AIbusuness_img_3.png" alt="sub-img3" /></div>
                </>
              ) : myLanguage === ENG ? (
                <>
                  <div className="tit">
                    <span>
                      <strong>Heart-Warming</strong>
                    </span>
                    <span>AI friends are waiting for you.</span>
                    <div className="tags">
                      <i># customized</i>
                      <i># Best friend</i>
                      <i># Various Persona</i>
                    </div>
                  </div>
                  <div className="image"><img src="/images/sub/AIbusuness_img_3.png" alt="sub-img3" /></div>
                </>
              ) : null
            }
          </p>
        </div>
      </div>
      <div className="persona-box app">
        <ul>
          <li>
            {
              myLanguage === KOR ? (
                <div className="app-intro">
                  <div className="txt">
                  <p>Hiing is your true AI friend that remembers your worries and the <span>conversations</span> we’ve shared, checking in with you from a place of <span>genuine care.</span></p>
                  </div>
                  <div className="photo">
                    <img src="/images/eng/AIbusuness_img_4.png" alt="포그니와의 대화" />
                  </div>
                </div>
              ) : myLanguage === ENG ? (
                <div className="app-intro">
                  <div className="txt">
                    <p>Hiing is your true AI friend that remembers your worries and the <span>conversations</span> we’ve shared, checking in with you from a place of <span>genuine care.</span></p>
                  </div>
                  <div className="photo">
                    <img src="/images/eng/AIbusuness_img_4.png" alt="포그니와의 대화" />
                  </div>
                </div>
              ) : null
            }
          </li>
          <li
            className="second"
            style={{
              'backgroundImage': `url('/images/sub/AIbusuness_img_5_bg.png')`,
              'backgroundPosition': 'center',
              'backgroundSize': 'cover',
              'backgroundRepeat': 'no-repeat'
            }}
          >
            {
              myLanguage === KOR ? (
                <div className="contents">
                  <div className="left">
                    <div className="photo">
                      <img src="/images/eng/AIbusuness_img_5-1.png" alt="" />
                    </div>
                  </div>
                  <div className="right">
                    <div className="txt">
                      <p>모든 대화는 <span>안전하게 보호</span>되며, 하잉은 <span>정신 건강</span> 전문가들과의 협력을 통해 의미 있고 효과적인 대화를 제공하여 <span>긍정적인 변화</span>를 줄 수 있도록 합니다</p>
                    </div>
                    <div className="image">
                      <img src="/images/sub/AIbusuness_img_5-2.png" alt="" />
                    </div>
                  </div>
                </div>
              ) : myLanguage === ENG ? (
                <div className="contents">
                  <div className="left">
                    <div className="photo">
                      <img src="/images/eng/AIbusuness_img_5-1.png" alt="" />
                    </div>
                  </div>
                  <div className="right">
                    <div className="txt">
                      <p>In collaboration with <span>mental health</span> professionals, all conversations are <span>securely protected,</span> offering you meaningful and effective dialogue that can inspire real <span>positive change.</span></p>
                    </div>
                    <div className="image">
                      <img src="/images/sub/AIbusuness_img_5-2.png" alt="" />
                    </div>
                  </div>
                </div>
              ) : null
            }
          </li>
          <li className="third">
            {
              myLanguage === KOR ? (
                <div className="contents">
                  <div className="txt">
                    <p>Hiing은 마음을 이해하고 <span>감정을 공감</span>하며, 그 이해를 바탕으로 <span>공감하는 행동과 표현</span>을 통해 <span>관계적인 만족감</span>을 느낄 수 있도록 합니다.</p>
                  </div>
                  <div className="bg">
                    <img src="/images/eng/AIbusuness_img_6.png" alt="" />
                  </div>
                  <div className="bg-animate"><img src="/images/sub/emotion_pogny_joyful.webp" alt="animate" /></div>
                </div>
              ) : myLanguage === ENG ? (
                <div className="contents">
                  <div className="txt">
                    <p>Hiing understands your heart’s desires, <span>connects with your emotions,</span> and turns that understanding into <span>empathetic actions,</span> helping you build <span>genuine connections.</span></p>
                  </div>
                  <div className="bg">
                    <img src="/images/eng/AIbusuness_img_6.png" alt="" />
                  </div>
                  <div className="bg-animate"><img src="/images/sub/emotion_pogny_joyful.webp" alt="animate" /></div>
                </div>
              ) : null
            }
          </li>
          <li className="fourth">
            {
              myLanguage === KOR ? (
                <div className="contents">
                  <div className="image">
                    <img src="/images/eng/AIbusuness_img_7.png" alt="" />
                  </div>
                  <div className="txt">
                    <p><span>스스로를 칭찬하는 글</span>을 작성하면, Hiing은 칭찬을 인식하고 내용과 문맥을 분석하여, <span>개인 맞춤형 AI 칭찬 카드</span>를 만들어 <span>자존감</span>을 높이는 데 도움을 줍니다.</p>
                  </div>
                </div>
              ) : myLanguage === ENG ? (
                <div className="contents">
                  <div className="image">
                    <img src="/images/eng/AIbusuness_img_7.png" alt="" />
                  </div>
                  <div className="txt">
                    <p>By writing your <span>self-compliment diary</span> daily, Hiing recognizes and evaluates your compliments, creating <span>personalized AI compliment cards</span> based on <span>context-driven image generation</span> to boost your <span>self-esteem.</span></p>
                  </div>
                </div>
              ) : null
            }
          </li>
        </ul>
      </div>
      <div className="persona-box outlink">
        {
          myLanguage === KOR ? (
            <>
              <dl>
                <dt>
                  <span>“Hiing의 친구들과 더 쉽게, 그리고 깊게 소통하고 가까워지세요.”</span>
                </dt>
                {/* <dd>
                  <span>
                    Please search for <strong>'pogny' </strong>and <strong>'HANA' </strong>on KakaoTalk 
                    <span> channel and Facebook Messenger.</span>
                  </span>
                </dd> */}
                <div className="image"><img src="/images/eng/AIbusuness_img_8.png" alt="" /></div>
              </dl>
            </>
          ) : myLanguage === ENG ? (
            <>
              <dl>
                <dt>
                  <span>“You can connect with Hiing’s friends more easily and personally, bringing them closer to you than ever before.”</span>
                </dt>
                {/* <dd>
                  <span>
                    Please search for <strong>'pogny' </strong>and <strong>'HANA' </strong>on KakaoTalk 
                    <span> channel and Facebook Messenger.</span>
                  </span>
                </dd> */}
                <div className="image"><img src="/images/eng/AIbusuness_img_8.png" alt="" /></div>
              </dl>
            </>
          ) : null
        }
      </div>
    </>
  )
}

export const TechnologyInfo = () => {
  const { myLanguage } = useContext(BigglzContext);

  return (
    <>
      <div className="persona-box-wrap">
        <div className="persona-box service1">
          <h3>
            {
              myLanguage === KOR ? (
                <>
                  <span>Introducing Bigglz</span>
                  <span><strong>나를 이해해주는 대화로 위로받아보세요.</strong></span>
                </>
              ) : myLanguage === ENG ? (
                <>
                  <span>Introducing Bigglz</span>
                  <span><strong>Find Comfort in Conversations</strong></span>
                  <span><strong>That Understand You.</strong></span>
                </>
              ) : null
            }
          </h3>
          <div className="service-area">
            {
              myLanguage === KOR ? (
                <>
                  <div className="item">
                      <div className="icon"><img src="/images/sub/ico_item_service1.png" alt="icon" /></div>
                      Hiing Agent AI 챗봇과 함께 일상적인 작은 걱정부터 깊은 심리적 고민과 불안까지 모든 것을 나눌 수 있습니다.
                    </div>
                    <div className="item">
                      <div className="icon"><img src="/images/sub/ico_item_service2.png" alt="icon" /></div>
                      Hiing은 대화를 기반으로 적합한 이미지를 생성하면서 캐릭터의 일관성을 보장합니다.
                    </div>
                    <div className="item">
                      <div className="icon"><img src="/images/sub/ico_item_service3.png" alt="icon" /></div>
                      Hiing은 친환경 NFC 통신을 통해 터치 가능한 피규어와 상호작용하여 서비스를 원활하게 이용할 수 있게 합니다.
                    </div>
                    <div className="item">
                      <div className="icon"><img src="/images/sub/ico_item_service4.png" alt="icon" /></div>
                      SNS에서 캐릭터의 페르소나를 담아내어, 팬들과 자동으로 소통할 수 있도록 합니다.  자연스러운 소통을 통해 캐릭터와 팬 사이의 긴밀한 연결을 형성시켜줍니다.
                    </div>
                </>
              ) : myLanguage === ENG ? (
                  <>
                    <div className="item">
                      <div className="icon"><img src="/images/sub/ico_item_service1.png" alt="icon" /></div>
                      Whith the <strong>Hiing Agent AI chatbot.</strong> you can share everything from small daily worries to deeper psychological concerns and anxieties.
                    </div>
                    <div className="item">
                      <div className="icon"><img src="/images/sub/ico_item_service2.png" alt="icon" /></div>
                      Hiing ensures <strong>character consistency</strong> while generating fitting images based on conversations.
                    </div>
                    <div className="item">
                      <div className="icon"><img src="/images/sub/ico_item_service3.png" alt="icon" /></div>
                      Through eco-friendly NFC communication, Hiing allows you to interact with <strong>touchable figures</strong> to access our services seamlessly.
                    </div>
                    <div className="item">
                      <div className="icon"><img src="/images/sub/ico_item_service4.png" alt="icon" /></div>
                      Our technology automatically <strong>engages with fans on social media,</strong> fostering close connections through timely comments.
                    </div>
                  </>
                ) : null
            }
          </div>
          <div className="image-area">
            <img src="/images/sub/AITechnology_img_1.png" alt="tech" />
          </div>
        </div>
      </div>
      <div className="persona-box-wrap">
        <div className="persona-box service2">
          <div className="txt">
            {
              myLanguage === KOR ? (
                <>
                  <div className="tit">
                    <h2>챗봇</h2>
                    <p>AI 챗봇과 함께 의미 있는 대화를 나누세요. 공감능력이 뛰어난  하잉의 AI 챗봇은 개인 별 필요에 맞춰 제공되어 멘탈 케어에 도움을 줍니다.</p>
                  </div>
                  <div className="mo-image"><img src="/images/eng/AITechnology_img_2.png" alt="AITechnology_img_2" /></div>
                  <div className="desc">
                    <p>Mental wellness bot</p>
                    <p>implemented with Bigglz’s</p>
                    <p className="highlight">specialized natural language</p>
                    <p className="highlight">processing technology</p>
                  </div>
                </>
              ) : myLanguage === ENG ? (
                <>
                  <div className="tit">
                    <h2>Chatbot</h2>
                    <p>Engage in meaningful conversations with our advanced AI chatbot, designed to provide empathetic and supportive dialogues. Tailored to your needs, our chatbot enhances your mental health journey.</p>
                  </div>
                  <div className="mo-image"><img src="/images/eng/AITechnology_img_2.png" alt="AITechnology_img_2" /></div>
                  <div className="desc">
                    <p>Mental wellness bot</p>
                    <p>implemented with Bigglz’s</p>
                    <p className="highlight">specialized natural language</p>
                    <p className="highlight">processing technology</p>
                  </div>
                </>
              ) : null
            }
          </div>
          <div className="service-box">
            <div className="service-area">
              <div className="image"><img src="/images/eng/AITechnology_img_2.png" alt="AITechnology_img_2" /></div>
            </div>
          </div>
        </div>
      </div>
      <div className="persona-box-wrap">
        <div className="persona-box service3">
          <div className="service-box">
            <div className="service-area">
              <div className="image"><img src="/images/eng/AITechnology_img_4.png" alt="AITechnology_img_4" /></div>
            </div>
          </div>
          <div className="txt">
            {
              myLanguage === KOR ? (
                <>
                  <div className="tit">
                    <h2>셀프 칭찬 다이어리</h2>
                  </div>
                  <div className="desc">
                    <p>셀프칭찬 다이어리를 통해 자신감을 키워보세요. 하잉에서는 나의 성취를 기록하고
                    되돌아볼 수 있습니다.
                    긍정적인 마음가짐을 형성하고 감정적 회복력을
                    높이는 개인 맞춤형 콘텐츠를 경험해보세요.</p>
                  </div>
                </>
              ) : myLanguage === ENG ? (
                <>
                  <div className="tit">
                    <h2>Self</h2>
                    <h2>Compliment Diary</h2>
                  </div>
                  <div className="desc">
                    <p>Boost your confidence with
                    our self-compliment diary,
                    a digital space to record and reflect
                    on your achievements.
                    It’s your personal tool for building a positive mindset and enhancing
                    emotional resilience.</p>
                  </div>
                </>
              ) : null
            }
          </div>
        </div>
      </div>
      <div className="persona-box-wrap">
        <div className="persona-box service4">
          <div className="service-box">
            {
              myLanguage === KOR ? (
                <>
                  <div className="tit">
                    <h2>Interactive</h2>
                    <h2>Plush Toy</h2>
                  </div>
                  <div className="service-area">
                    <div className="image"><img src="/images/eng/AITechnology_img_6.png" alt="AITechnology_img_6" /></div>
                    <div className="desc">
                      NFC 기능이 탑재된 인형들을 만나보세요. 인형과 앱이 연결되어, 촉감으로 느끼는 안락함과 소통에 대한 만족감을 함께 느낄 수 있어요. NFC 인형은 감성 지능을 향상시키고, 더 건강하고 행복한 삶을 만들어갈 수 있게 도와줍니다.
                    </div>
                  </div>
                </>
              ) : myLanguage === ENG ? (
                <>
                  <div className="tit">
                    <h2>Interactive</h2>
                    <h2>Plush Toy</h2>
                  </div>
                  <div className="service-area">
                    <div className="image"><img src="/images/eng/AITechnology_img_6.png" alt="AITechnology_img_6" /></div>
                    <div className="desc">
                      Meet our NFC-enabled plush toys that connect seamlessly with our app, providing a unique blend of physical comfort and digital engagement. These toys foster emotional intelligence and offer interactive support for a healthier, happier you.
                    </div>
                  </div>
                </>
              ) : null
            }
          </div>
        </div>
      </div>
      <div className="persona-box-wrap service5">
        <div className="persona-box service5">
          <div className="service-box">
            {
              myLanguage === KOR ? (
                <>
                  <div className="txt">
                    <h2>간편한 SNS AI 자동 응답</h2>
                    <p>SNS 계정의 고유한 톤과 지식을 사용하여 효과적인 응답을 자동화합니다.
                    실시간 다국어 지원 기능을 통해 텍스트와 이미지를 모두 이해하며,
                    정성스럽고 신중한 소통을 할 수 있도록 솔루션을 제공합니다.</p>
                  </div>
                  <div className="image-area">
                    <div className="image"><img src="/images/eng/AITechnology_img_5.png" alt="AITechnology_img_5" /></div>
                  </div>
                  <div className="button">
                    <button><a href={ outLinks.bigglzPetpal } target="_blank">Try AI Comments</a></button>
                  </div>
                </>
              ) : myLanguage === ENG ? (
                <>
                  <div className="txt">
                    <h2>Effortless Automated Social Media Responses</h2>
                    <p>Our solution quietly and effectively automates responses using
                    your account’s unique tone and knowledge. With real-time, multilingual support,
                    it understands both text and images, ensuring thoughtful engagement.</p>
                  </div>
                  <div className="image-area">
                    <div className="image"><img src="/images/eng/AITechnology_img_5.png" alt="AITechnology_img_5" /></div>
                  </div>
                  <div className="button">
                    <button><a href={ outLinks.bigglzPetpal } target="_blank">Try AI Comments</a></button>
                  </div>
                </>
              ) : null
            }
          </div>
        </div>
      </div>
      <div className="persona-box-wrap service6">
        <div className="persona-box service6">
          <div className="service-box">
            {
              myLanguage === KOR ? (
                <>
                  <div className="tit">
                    <p>Bigglz's Agent AI Mental wellness bot is
                    helping many comnpanies at home and abroad develop services.</p>
                  </div>
                  <div className="service-area">
                    <div className="item">
                      <div className="icon"><img src="/images/sub/ico_item_service5_1.png" alt="icon" /></div>
                      <div className="txt">
                        <h2>Expansion of IP business into chatbots</h2>
                        <p>We are developing a Persona AI Mental wellness bot for youth-targeted communication with companies that have famous international IPs.</p>
                      </div>
                    </div>
                    <div className="item">
                      <div className="icon"><img src="/images/sub/ico_item_service5_2.png" alt="icon" /></div>
                      <div className="txt">
                        <h2>Interactive CS Solutions</h2>
                        <p>We are helping to advance services by suggesting a new CS solution based on Persona AI from the existing scenario-based CS.</p>
                      </div>
                    </div>
                    <div className="item">
                      <div className="icon"><img src="/images/sub/ico_item_service5_3.png" alt="icon" /></div>
                      <div className="txt">
                        <h2>Provide counseling data</h2>
                        <p>We provide consultation data for machine learning and provide information so that the data can be processed according to user properties.</p>
                      </div>
                    </div>
                    <div className="item">
                      <div className="icon"><img src="/images/sub/ico_item_service5_4.png" alt="icon" /></div>
                      <div className="txt">
                        <h2>Supporting Mental Health</h2>
                        <p>Our platform uses advanced AI to foster empathetic conversations with teens about their well-being, offering a cost-effective alternative to in-person counseling.</p>
                      </div>
                    </div>
                  </div>
                </>
              ) : myLanguage === ENG ? (
                <>
                  <div className="tit">
                    <p>Bigglz's Agent AI Mental wellness bot is
                    helping many comnpanies at home and abroad develop services.</p>
                  </div>
                  <div className="service-area">
                    <div className="item">
                      <div className="icon"><img src="/images/sub/ico_item_service5_1.png" alt="icon" /></div>
                      <div className="txt">
                        <h2>Expansion of IP business into chatbots</h2>
                        <p>We are developing a Persona AI Mental wellness bot for youth-targeted communication with companies that have famous international IPs.</p>
                      </div>
                    </div>
                    <div className="item">
                      <div className="icon"><img src="/images/sub/ico_item_service5_2.png" alt="icon" /></div>
                      <div className="txt">
                        <h2>Interactive CS Solutions</h2>
                        <p>We are helping to advance services by suggesting a new CS solution based on Persona AI from the existing scenario-based CS.</p>
                      </div>
                    </div>
                    <div className="item">
                      <div className="icon"><img src="/images/sub/ico_item_service5_3.png" alt="icon" /></div>
                      <div className="txt">
                        <h2>Provide counseling data</h2>
                        <p>We provide consultation data for machine learning and provide information so that the data can be processed according to user properties.</p>
                      </div>
                    </div>
                    <div className="item">
                      <div className="icon"><img src="/images/sub/ico_item_service5_4.png" alt="icon" /></div>
                      <div className="txt">
                        <h2>Supporting Mental Health</h2>
                        <p>Our platform uses advanced AI to foster empathetic conversations with teens about their well-being, offering a cost-effective alternative to in-person counseling.</p>
                      </div>
                    </div>
                  </div>
                </>
              ) : null
            }
          </div>
        </div>
      </div>
    </>
  )
}