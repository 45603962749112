import React from "react";
import { NewsList } from "./components/newsList";

const News = () => {
  return (
    <>
      <section className='news'>
        <article className='visual'>
          <h2>뉴스</h2>
        </article>
        <article className='content'>
          <div className='news-box'>
            <div className='news-area'>
              <NewsList />
            </div>
            <ul className='pagination01'>
              <li className='prev'>
                <a href='#none'>
                  <img src='/images/common/btn_prev.png' alt='이전으로' />
                </a>
              </li>
              <li className='on'>
                <a href='#none'>1</a>
              </li>
              <li>
                <a href='#none'>2</a>
              </li>
              <li>
                <a href='#none'>3</a>
              </li>
              <li>
                <a href='#none'>4</a>
              </li>
              <li>
                <a href='#none'>5</a>
              </li>
              <li className='next'>
                <a href='#none'>
                  <img src='/images/common/btn_next.png' alt='다음으로' />
                </a>
              </li>
            </ul>
          </div>
        </article>
      </section>
    </>
  );
};

export default News;
