import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { outLinks } from '../../routes/routes';
import { BigglzContext } from '../../utils/contextProvider';
import { ENG, KOR } from '../../layout/layout';

const Footer = () => {
  const { myLanguage } = useContext(BigglzContext);
  const onChange = (e) => {
    const { value } = e.target;
    if (value !== '') window.open(value);
  }

  return (
    <footer>
      <p className='txt'>
        {
          myLanguage === KOR
            ? <>
              비글즈는 <strong>전세계 크리에이터</strong>와 함께합니다.
            </>
            : myLanguage === ENG
              ? <>
                <strong>bigglz</strong> is joined by creators around the world.
              </>
              : null
        }
      </p>
      <div className='info-box clear'>
        <h1>
          <Link to="/">
            <img src='/images/common/footer_logo.png' alt='bigglz' />
          </Link>
        </h1>
        <div className='info'>
          <dl className='contact'>
            <dt>Contact Us</dt>
            <dd>
              <span>T</span><a href='tel:070-76185339'>070 7618 5339</a>
            </dd>
            <dd>
              <span>E</span><a href='mailto:contact@bigglz.com'>contact@bigglz.com</a>
            </dd>
          </dl>
          <dl className='address'>
            <dt>Address</dt>
            <dd>
              {
                myLanguage === KOR
                  ? <>
                    <span>본사</span>대전광역시 유성구 어은로51번길 44 (어은동) 지하 1층
                  </>
                  : myLanguage === ENG
                    ? <>
                      <span>Headquarters</span>4, Eoeun-ro 51beon-gil, Yuseong-gu, Daejeon, Republic of Korea, B1
                    </>
                    : null
              }
            </dd>
            <dd>
              {
                myLanguage === KOR
                  ? <>
                    <span>연구소</span>경기도 성남시 분당구 벌말로50번길 41 투아이센터 5층
                  </>
                  : myLanguage === ENG
                    ? <>
                      <span>Branch offices</span>41, Beolmal-ro 50beon-gil, Bundang-gu,<br /> Seongnam-si, Gyeonggi-do, Republic of Korea /<br /> 2i Center 5F, Bigglz.inc
                    </>
                    : null
              }
            </dd>
          </dl>
        </div>
        <select onChange={onChange} value={""} name="FamilySite">
          <option value="" hidden>Family Site</option>
          {
            myLanguage === KOR
              ? <option value={outLinks.gallery}>아트 GG 갤러리</option>
              : myLanguage === ENG
                ? <option value={outLinks.gallery}>art GG gallery</option>
                : null
          }
        </select>
      </div>
      <p className='copyright'>
        {
          myLanguage === KOR
            ? <>Copyright ⓒ bigglz. All rights reserved.</>
            : myLanguage === ENG
              ? <>Copyright ⓒ bigglz, Inc.</>
              : null
        }
      </p>
      <ul className='sns'>
        <li>
          <a href={outLinks.bigglzInsta} target="_blank" rel="noopener noreferrer">
            <img src='/images/common/ic_instargram02.png' alt='인스타그램' />
          </a>
        </li>
      </ul>
    </footer>
  );
};

export default Footer;
