import React, { useRef, useEffect } from "react";
import Swiper, { Pagination } from "swiper";

Swiper.use([Pagination]);

export const OfficePictureSwipe = ({ num }) => {
    const swipeRef = useRef(null);

    useEffect(() => {
      if (swipeRef.current !== null) {
        // 스와이프 슬라이드
        new Swiper('.photo', {
          slidesPerView: 3,
          loop: true,
          centeredSlides: true,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
          breakpoints: {
            1: {
              slidesPerView: 1,  //브라우저가 768보다 클 때
              spaceBetween: 0,
            },
            1024: {
              slidesPerView: 3,  //브라우저가 1024보다 클 때
              spaceBetween: 15,
            },
          },
        });
      }
    }, []);
    
    return (
      <>
        <div className="photo swiper-container" ref={(el) => { swipeRef.current = el; }}>
          <ul className="swiper-wrapper">
            {
              [...Array(num)].map((_, index) => (
                <li
                  key={index}
                  className="swiper-slide"
                >
                    <figure>
                        <img src={`/images/picture/bigglz_inside${index + 1}.png`} alt={`비글즈 사무실 내부사진${index + 1}`} />
                    </figure>
                </li>
              ))
            }
          </ul>
          <div className="swiper-button-prev btn-office"></div>
          <div className="swiper-button-next btn-office"></div>
          <div className='swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal'></div>
        </div>
      </>
    )
}