import React from "react";
import { Intro } from "./components/intro";

const AutomaticCommentIndex = () => {
  return (
    <>
      <section className='autoComment'>
        <article className='visual'>
          <h2>자동 댓글 기능</h2>
        </article>
        <article className='content'>
          <Intro />
        </article>
      </section>
    </>
  );
};

export default AutomaticCommentIndex;
