import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguaeDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguaeDetector) // 사용자 언어 탐지
  .use(initReactI18next) // i18n 객체를 react-18next에 전달
  .init({
    // for all options read: https://www.i18next.com/overview/configuration-options
    lng: "en", // 기본 언어
    debug: false,
    fallbackLng: "en", // 사용자 언어를 감지하지 못할 경우
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: {
          header: {
            primary: {
              gnb0: "Bigglz Instagram",
              gnb0_1: "Petpal five",
              gnb1: "Company",
              gnb2: "Service",
              gnb3: "AI Business",
              gnb4: "Character IP",
            },
            secondary: {
              gnb1: "News",
              gnb2: "Recruit",
            }
            // gnb8: "AI Comment",
            // gnb9: "Contact",
          },
          service: {
            categori1: "Hiing App",
            categori2: "Bigglz App",
            categori3: "Character IP",
            categori4: "Art gg gallery",
            // categori5: "AI Chatbot",
          },
          aiChatbot: {
            tab1: "Hiing app",
            tab2: "Technology introduce",
          }
        },
      },
      ko: {
        translation: {
          header: {
            primary: {
              gnb0: "비글즈 인스타그램",
              gnb0_1: "펫팔파이브",
              gnb1: "회사소개",
              gnb2: "서비스",
              gnb3: "AI 비즈니스",
              gnb4: "캐릭터 IP",
            },
            secondary: {
              gnb1: "뉴스",
              gnb2: "채용",
            }
            // gnb8: "자동 댓글 기능",
            // gnb9: "Contact",
          },
          service: {
            categori1: "앱",
            categori2: "캐릭터",
            categori3: "카페",
            categori4: "갤러리",
            categori5: "AI 힐링봇",
          },
          aiChatbot: {
            tab1: "하잉 앱",
            tab2: "기술소개",
          }
        },
      },
    },
  });

export default i18n;