import React, { useContext } from "react";
import { BigglzContext } from "../../../../utils/contextProvider";
import { ENG, KOR } from "../../../../layout/layout";

const CardFront = (props) => {
  return (
    <div className="front">
      <figure>
        <img src={`/images/sub/card_${props.positionName1}.png`} alt={props.positionName2} />
      </figure>
      <div className='txt'>{props.positionName2}</div>
    </div>
  )
}

const CardBackDeveloper = () => {
  const { myLanguage } = useContext(BigglzContext);

  return (
    <div className='back'>
      {
        myLanguage === KOR ? (
          <>
            <div className='tit'>이런일을 하게 될거에요</div>
            <div className='txt'>
              유니티 클라이언트 개발과 백엔드 개발,<br />
              그리고 AI 개발팀으로 나뉘어져 있어요.<br />
              열정적인 마인드를 가지고 있는 개발자를 찾고 있어요.
            </div>
          </>
        ) : myLanguage === ENG ? (
          <>
            <div className='tit'>Our teams</div>
            <div className='txt'>
              Our teams are divided into Unity client development,
              <span>backend development, and AI development teams.</span>
              <span>We are looking for developers with Passion!</span>
            </div>
          </>
        ) : null
      }
    </div>
  );
}

const CardBackUiUx = () => {
  const { myLanguage } = useContext(BigglzContext);
  
  return (
    <div className='back'>
      {
        myLanguage === KOR ? (
          <>
            <div className='tit'>이런일을 하게 될거에요</div>
            <div className='txt'>
              자사 Web·App 디자인을 비롯해<br />
              디자인 영역에서 성공적인 서비스로<br />
              나아갈 수 있는 방법을 탐색해요.
            </div>
          </>
        ) : myLanguage === ENG ? (
          <>
            <div className='tit'>Our teams</div>
            <div className='txt'>
              Explore ways to move toward
              <span>successful services in design areas,</span>
              <span> including our web and app design</span>
            </div>
          </>
        ) : null
      }
    </div>
  );
}

const CardBackCharacter = () => {
  const { myLanguage } = useContext(BigglzContext);

  return (
    <div className='back'>
      {
        myLanguage === KOR ? (
          <>
            <div className='tit'>이런일을 하게 될거에요</div>
            <div className='txt'>
              비글즈의 캐릭터를 디자인하고<br />
              필요한 원화와 리소스, 2D 일러스트<br />
              그리고 캐릭터 모션을 제작해요.
            </div>
          </>
        ) : myLanguage === ENG ? (
          <>
            <div className='tit'>Our teams</div>
            <div className='txt'>
              Design bigglz’s characters and produce
              <span>necessary original and resource,</span>
              <span>2d illustration, and character motion</span>
            </div>
          </>
        ) : null
      }
    </div>
  );
}

const CardBackManger = () => {
  const { myLanguage } = useContext(BigglzContext);

  return (
    <div className='back'>
      {
        myLanguage === KOR ? (
          <>
            <div className='tit'>이런일을 하게 될거에요</div>
            <div className='txt'>
              비글즈 서비스에 대한 전반적인<br />
              기획 및 전체 프로젝트를<br />
              관리해요.
            </div>
          </>
        ) : myLanguage === ENG ? (
          <>
            <div className='tit'>Our teams</div>
            <div className='txt'>
              Manage over all planning,
              <span>and the whole project for bigglz service</span>
            </div>
          </>
        ) : null
      }
    </div>
  );
}

export const RecruitList = () => {
  const { myLanguage } = useContext(BigglzContext);

  return (
    <>
      <h3>
        {
          myLanguage === KOR ? (
            <>
              Bigglz에서는 이런 일을 하게 될 거예요
            </>
          ) : myLanguage === ENG ? (
            <>
              We are
              <span>looking for <strong>talented people</strong></span>
              <span>to work with us!</span>
            </>
          ) : null
        }
      </h3>
      <ul className='position-list'>
        <li className='developer'>
          <CardFront
            positionName1={'developer'}
            positionName2={'Developer'}
          />
          <CardBackDeveloper />
        </li>
        <li className='uiux'>
          <CardFront
            positionName1={'uiux'}
            positionName2={'UI/UX Designer'}
          />
          <CardBackUiUx />
        </li>
        <li className='character'>
          <CardFront
            positionName1={'character'}
            positionName2={'Character Designer'}
          />
          <CardBackCharacter />
        </li>
        <li className='manger'>
          <CardFront
            positionName1={'manager'}
            positionName2={'Product manager'}
          />
          <CardBackManger />
        </li>
      </ul>
    </>
  );
}