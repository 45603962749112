import React, { useContext, useState, useEffect } from 'react';
import { BigglzContext } from "../../../../utils/contextProvider";
import { ENG, KOR } from "../../../../layout/layout";


const ListForm = ({ data, img, url }) => {

  return (
    <li>
      <a href={url} target='_blank' rel="noopener noreferrer">
        <span className='thum'>
          <img src={img} alt={img} />
        </span>
        <span className='info'>
          <span className='kind'>{data.kind}</span>
          <span className='tit'>
            <span>{data.tit}</span>
          </span>
          <span className='data'>
            <span className='date'>{data.date}</span>
            <span className='view'>0</span>
          </span>
        </span>
      </a>
    </li>
  );
}

export const NewsList = () => {
    const { myLanguage } = useContext(BigglzContext);
    const [newsContents, setNewsContents] = useState([]);

    useEffect(() => {
        const fetchNews = async () => {
            try {
                /*
                * 개발계 : https://test-admin.bigglz.com/api/news
                * 운영계 : https://admin.bigglz.com/api/news
                */

                const response = await fetch("https://admin.bigglz.com/api/news");

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                
                setNewsContents(data.newsContents);
            } catch (error) {
                console.error('Error fetching news:', error);
            }
        };

        fetchNews();
    }, []);

  return (
    <>
      <h3>
        {
          myLanguage === KOR ? (
            <>
              언론 속 Bigglz
            </>
          ) : myLanguage === ENG ? (
            <>
              Bigglz in the media
            </>
          ) : null
        }
      </h3>
      <ul className='news-list'>
        {
          newsContents.map((value, index) => (
            <ListForm
              key={index}
              data={myLanguage === KOR ? value.lang.kor : value.lang.eng}
              img={value.imgUrl}
              url={value.link}
            />
          ))
        }
      </ul>
    </>
  );
}