import React from "react";
import { ServiceTitTxt, CardInfo, CesTxt } from "./components/service";

const ServiceIndex = () => {

	return (
		<>
			<section className='service'>
				<article className='visual'>
					<div className="visual-image"><img src="/images/sub/bg_visual02.jpg" alt="visual" /></div>
					<div className="mo-visual-image"><img src="/images/sub/m-bg_visual02.png" alt="visual" /></div>
					<div className="content">
						<div className="center">
							<div className="text-image"><img src="/images/sub/tit_hiing_white.png" alt="bg-hiing" /></div>
							<h2>Meet Your Own AI Friends</h2>
							<p>Share Your Heart and Enjoy Together</p>
							<p>with Emotional & Warm Characters.</p>
							<p className="highlight">AI friend who always say “Hi” to you, Hiing</p>
							<div className="float-icons">
								<div className="top">
									<div className="logo"><img src="/images/sub/symbol_hiing.png" alt="symbol_hiing" /></div>
									<div className="ces"><img src="/images/sub/ces_Logo.png" alt="ces_Logo" /></div>
								</div>
								<div className="hiing"><img src="/images/sub/tit_hiing_white.png" alt="tit_hiing_white" /></div>
							</div>
						</div>
					</div>
				</article>
				<article className='content'>
					<div className='txt-box'>
						<ServiceTitTxt />
					</div>
					<div className='txt-box'>
						<CesTxt />
					</div>
					<div className='info'>
						<CardInfo />
					</div>
				</article>
			</section>
		</>
	);
};

export default ServiceIndex;
