import React, { useContext } from "react";
import { outLinks } from "../../../../routes/routes";
import { BigglzContext } from "../../../../utils/contextProvider";
import { ENG, KOR } from "../../../../layout/layout";

export const Intro = () => {
  const { myLanguage } = useContext(BigglzContext);

  return (
    <>
      <div className='intro-box'>
        <div className="intro-area">
          <div className="tit">
            Automatic <span>comment function</span>
          </div>
          <div className="photo">
            <figure>
              <img src="/images/eng/eng_ray_bubble_txt.png" alt="레이의 말풍선 이미지" />
            </figure>
          </div>
        </div>
        <div className="func-copy">
          <div className="photo">
            <figure>
              <img src="/images/eng/eng_img_automatic_copy.png" alt="자동댓글기능 내용 이미지" />
            </figure>
          </div>
        </div>
        {
          myLanguage === KOR ? (
            <>
              <div className="btn-area">
                <a
                  href={outLinks.bigglzPetpal}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  자동댓글기능 사용해보기
                </a>
              </div>
            </>
          ) : myLanguage === ENG ? (
            <>
              <div className="btn-area">
                <a
                  href={outLinks.bigglzPetpal}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Try AI comments
                </a>
              </div>
            </>
          ) : null
        }
      </div>
    </>
  );
};

export default Intro;
