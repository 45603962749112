import React, { useContext } from "react";
import { BigglzContext } from "../../../../utils/contextProvider";
import { ENG, KOR } from "../../../../layout/layout";

export const VisualPetRoom = () => {
  const { myLanguage } = useContext(BigglzContext);

  return (
    <>
      <div className='kind'>
        {
          myLanguage === KOR ? (
            <>
              펫룸
            </>
          ) : myLanguage === ENG ? (
            <>
              Pet room
            </>
          ) : null
        }
      </div>
      <h2>
        {
          myLanguage === KOR ? (
            <>
              너와 나의 펫과
              <span>함께 하는 소중하고</span>
              <span>
                  안락한 <strong>공간</strong>
              </span>
            </>
          ) : myLanguage === ENG ? (
            <>
              A safe and
              <span>comfortable <strong>space</strong></span>
              <span> for you and your pet.</span>
            </>
          ) : null
        }
      </h2>
      <div className='txt'>
        {
          myLanguage === KOR ? (
            <>
              내 맘대로 꾸미는 방 안에서
              <span>귀여운 펫과 함께 동거동락 해보세요</span>
            </>
          ) : myLanguage === ENG ? (
            <>
              Enjoy spending time together In your pet's room that
              <span> you decorate the way you want.</span>
            </>
          ) : null
        }
      </div>
    </>
  );
}

export const VisualPetWorld = () => {
  const { myLanguage } = useContext(BigglzContext);

  return (
    <>
      <div className='kind'>
        {
          myLanguage === KOR ? (
            <>
              테마월드
            </>
          ) : myLanguage === ENG ? (
            <>
              Theme world
            </>
          ) : null
        }
      </div>
      <h2>
        {
          myLanguage === KOR ? (
            <>
              나의 펫을 데리고
              <span>다른 친구들과 모여</span>
              <span>
                  일상을 공유하는 <strong>테마월드</strong>
              </span>
            </>
          ) : myLanguage === ENG ? (
            <>
              Bring your pet and
              <span> gather with other friends</span>
              <span> to share your <strong>daily life</strong>.</span>
            </>
          ) : null
        }
      </h2>
      <div className='txt'>
        {
          myLanguage === KOR ? (
            <>
              따뜻한 모닥불이 있는 캠핑장,
              <span>웅장한 음악이 흐르는 무도회장 등 다양한 곳에서</span>
              <span>친구들과 함께 게임도 하며, 힐링 해보세요</span>
            </>
          ) : myLanguage === ENG ? (
            <>
              Play games and spend time with friends at Theme World,
              <span> including a camping site with a warm bonfire and</span>
              <span> a ballroom with funcky music.</span>
            </>
          ) : null
        }
      </div>
    </>
  );
}

export const VisualPetCrew = () => {
  const { myLanguage } = useContext(BigglzContext);

  return (
    <>
      <div className='kind'>
        {
          myLanguage === KOR ? (
            <>
              챗봇
            </>
          ) : myLanguage === ENG ? (
            <>
              Chat bot
            </>
          ) : null
        }
      </div>
      <h2>
        {
          myLanguage === KOR ? (
            <>
              사랑하는 나의 펫과
              <span>일상을 공유하며</span>
              <span>
                  힐링하는 특별한 <strong>대화</strong>
              </span>
            </>
          ) : myLanguage === ENG ? (
            <>
              A special <strong>conversation</strong>
              <span> that heals you by sharing your daily life</span>
              <span> with your beloved pet.</span>
            </>
          ) : null
        }
      </h2>
      <div className='txt'>
        {
          myLanguage === KOR ? (
            <>
              비글즈만의 페르소나 AI기술로
              <span>펫과 마음속 이야기를 해보세요</span>
            </>
          ) : myLanguage === ENG ? (
            <>
              Share your deepest feelings with your pet in a chat.
              <span> Your pet always knows just what to say.</span>
            </>
          ) : null
        }
      </div>
    </>
  );
}

export const VisualPetDiary = () => {
  const { myLanguage } = useContext(BigglzContext);

  return (
    <>
      <div className='kind'>
        {
          myLanguage === KOR ? (
            <>
              펫다이어리
            </>
          ) : myLanguage === ENG ? (
            <>
              Pet diary
            </>
          ) : null
        }
      </div>
      <h2>
        {
          myLanguage === KOR ? (
            <>
              내 <strong>다이어리</strong>를
              <span>스티커로</span>
              <span>예쁘게 다꾸다꾸</span>
            </>
          ) : myLanguage === ENG ? (
            <>
              Decorate your
              <span> Own <strong>Diary</strong></span>
              <span> with fancy stickers</span>
            </>
          ) : null
        }
      </h2>
      <div className='txt'>
        {
          myLanguage === KOR ? (
            <>
              나만의 다이어리를
              <span>나만의 방식으로 꾸며보세요</span>
            </>
          ) : myLanguage === ENG ? (
            <>
              Decorate your own diary
              <span> in your way of doing it.</span>
            </>
          ) : null
        }
      </div>
    </>
  );
}

export const VisualPetPal = () => {
  const { myLanguage } = useContext(BigglzContext);

  return (
    <>
      <div className='kind'>
          {
            myLanguage === KOR ? (
              <>펫팔</>
            ) : myLanguage === ENG ? (
              <>Pet pal</>
            ) : null
          }
      </div>
      <h2>
        {
          myLanguage === KOR ? (
            <>
              친구와
              <span>주고받는 아름다운</span>
              <span>
                  아날로그 <strong>감성</strong>
              </span>
            </>
          ) : myLanguage === ENG ? (
            <>
              A beautiful 
              <span> Analogue <strong>Feeling</strong>,</span>
              <span> sharing with your pals</span>
            </>
          ) : null
        }
      </h2>
      <div className='txt'>
        {
          myLanguage === KOR ? (
            <>
              친구들과 함께
              <span>소중한 편지를 주고받아 보세요</span>
            </>
          ) : myLanguage === ENG ? (
            <>
              Share precious letters
              <span> with your friends.</span>
            </>
          ) : null
        }
      </div>
    </>
  );
}