import React, { useRef, useEffect, useContext } from "react";
import { BigglzContext } from "../../../../utils/contextProvider";
import { ENG, KOR } from "../../../../layout/layout";

export const KakaoMap = () => {
  const kakaoMapRef = useRef(null);
  const { myLanguage } = useContext(BigglzContext);

  useEffect(() => {
    try {
      if (window.kakao !== undefined) {
        const options = { //지도를 생성할 때 필요한 기본 옵션
          center: new window.kakao.maps.LatLng(37.412047496255845, 127.14332296265643), //지도의 중심좌표.
          level: 2, //지도의 레벨(확대, 축소 정도)
        };
        let map = new window.kakao.maps.Map(kakaoMapRef.current, options); //지도 생성 및 객체 리턴
        let markerPosition = new window.kakao.maps.LatLng( //마커가 표시 될 위치
          37.412047496255845, 127.14332296265643
        );

        let imageSrc = '/images/sub/marker_location.png' // 마커이미지의 주소    
        let imageSize = new window.kakao.maps.Size(96, 96) // 마커이미지의 크기
        let markerImage = new window.kakao.maps.MarkerImage(imageSrc, imageSize) // 마커의 이미지정보를 가지고 있는 마커이미지 생성
        let imageTit = 'marker';

        let marker = new window.kakao.maps.Marker({ //마커를 생성
          position: markerPosition,
          image: markerImage, // 마커이미지 설정
          title: imageTit
        });

        marker.setMap(map);
      }
    }
    catch (err) { console.log("ERR: ", err.message) }
  }, []);

  return (
    <>
      <h3>
        {
          myLanguage === KOR ? (
            <>비글즈 연구소 위치</>
          ) : myLanguage === ENG ? (
            <>Bigglz location</>
          ) : null
        }
      </h3>
      <address>
        {
          myLanguage === KOR ? (
            <>경기도 성남시 <span>분당구 벌말로50번길 41 투아이센터 5층</span></>
          ) : myLanguage === ENG ? (
            <>
              <span>
                <span>Headquarters</span>
                44, Eoeun-ro 51beon-gil, Yuseong-gu, Daejeon, Republic of Korea, B1

                <span>Branch offices</span>
                41, Beolmal-ro 50beon-gil, Bundang-gu, Seongnam-si, Gyeonggi-do, Republic of Korea, 2i Center 5F
              </span>
            </>
          ) : null
        }
      </address>
      <div className="map" ref={kakaoMapRef}></div>
    </>
  );
}