import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { outLinks, routes } from "../../routes/routes";
import { useTranslation, Trans } from "react-i18next";
import { BigglzContext } from "../../utils/contextProvider";
import { ENG, KOR } from "../../layout/layout";

const Header = () => {
	const { headerRef, setMyLanguage } = useContext(BigglzContext);
	const { i18n } = useTranslation();
	const location = useLocation();
	const [selectLang, setSelectLang] = useState(i18n.language);
	const [menuIcon, setMenuIcon] = useState(false);
	const [headerNoneFix, setHeaderNoneFix] = useState("");

	useEffect(() => {
		if (location.pathname === "/Persona") {
			setHeaderNoneFix("none-fixed");
		} else {
			setHeaderNoneFix("");
		}
	}, [headerRef, location]);

	// 다국어 처리
	const handleLanguageClick = (event) => {
		const langValue = event.target.value;
		setMyLanguage(langValue);
		i18n.changeLanguage(langValue);
		setSelectLang(langValue);
	}

	// 페이지 경로
	const pathname = location.pathname;

	// 모바일 메뉴 토글
	const mobileMenuClick = () => setMenuIcon(!menuIcon);

	return (
		<header className={headerNoneFix} ref={headerRef}>
			<div className='header'>
				<ul className='sns'>
					<li className='instagram'>
						<a href={outLinks.bigglzInsta} target="_blank" rel="noopener noreferrer">인스타그램</a>
					</li>
				</ul>
				<h1>
					<Link to={routes.about}>
						<img src='/images/common/header_logo.png' alt='bigglz' />
					</Link>
				</h1>
				<select className="lang" value={selectLang} onChange={handleLanguageClick}>
					<option value={KOR}>한글</option>
					<option value={ENG}>English</option>
				</select>

				{/* 모바일용 메뉴 */}
				<div className='m-menu'>
					<div className={`icon ${menuIcon ? "on" : ""}`} onClick={mobileMenuClick}>
						<span></span>
						<span></span>
						<span></span>
					</div>
					<div className={`menu-box ${menuIcon ? "on" : ""}`}>
						
						<div className='m-gnb'>
							<div className="primary">
								<div className={`nav-item ${pathname && pathname.includes(routes.about) ? 'on' : ''}`}>
									<Link to={routes.about} onClick={mobileMenuClick}>
										<Trans i18nKey="header.primary.gnb1" />
									</Link>
								</div>
								<div className={`nav-item ${pathname && pathname.includes(routes.service) ? 'on' : ''}`}>
									<Link to={routes.service} onClick={mobileMenuClick}>
										<Trans i18nKey="header.primary.gnb2" />
									</Link>
								</div>
								<div className={`nav-item ${pathname && pathname.includes(routes.persona) ? 'on' : ''}`}>
									<Link to={routes.persona} onClick={mobileMenuClick}>
										<Trans i18nKey="header.primary.gnb3" />
									</Link>
								</div>
								<div className={`nav-item ${pathname && pathname.includes(routes.character) ? 'on' : ''}`}>
									<Link to={routes.character} onClick={mobileMenuClick}>
										<Trans i18nKey="header.primary.gnb4" />
									</Link>
								</div>
							</div>
							<span className="division-line"></span>
							<div className="secondary">
								<div className={`nav-item ${pathname && pathname.includes(routes.news) ? 'on' : ''}`}>
									<Link to={routes.news} onClick={mobileMenuClick}>
										<Trans i18nKey="header.secondary.gnb1" />
									</Link>
								</div>
								<div className={`nav-item ${pathname && pathname.includes(routes.recruit) ? 'on' : ''}`}>
									<Link to={routes.recruit} onClick={mobileMenuClick}>
										<Trans i18nKey="header.secondary.gnb2" />
									</Link>
								</div>
							</div>
							{/* <div className={`ai-comment ${pathname.includes(routes.automaticComment) ? 'on' : ''}`}>
								<Link to={routes.automaticComment} onClick={mobileMenuClick}>
									<Trans i18nKey="header.gnb8" />
								</Link>
							</div> */}
							{/* <li className={pathname.includes(routes.contact) ? 'on' : ''}>
								<Link to={routes.contact} onClick={mobileMenuClick}>
									<Trans i18nKey="header.gnb9" />
								</Link>
							</li> */}
						</div>
						<ul className='sns'>
							<li>
								<a href={outLinks.bigglzPetpal} target="_blank" rel="noopener noreferrer">
									<Trans i18nKey="header.primary.gnb0_1" />
								</a>
							</li>
							<li className='instagram'>
								<a href={outLinks.bigglzInsta} target="_blank" rel="noopener noreferrer">
									<Trans i18nKey="header.primary.gnb0" />
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div className='gnb'>
				<div className="between">
					<div className="primary">
						<div className={pathname.includes(routes.about) ? 'on' : ''}>
							<Link to={routes.about}>
								<Trans i18nKey="header.primary.gnb1" />
							</Link>
						</div>
						<div className={pathname.includes(routes.service) ? 'on' : ''}>
							<Link to={routes.service}>
								<Trans i18nKey="header.primary.gnb2" />
							</Link>
						</div>
						<div className={pathname.includes(routes.persona) ? 'on' : ''}>
							<Link to={routes.persona}>
								<Trans i18nKey="header.primary.gnb3" />
							</Link>
						</div>
						<div className={pathname.includes(routes.character) ? 'on' : ''}>
							<Link to={routes.character}>
								<Trans i18nKey="header.primary.gnb4" />
							</Link>
						</div>
					</div>
					<div className="secondary">
						<div className={pathname.includes(routes.news) ? 'on' : ''}>
							<Link to={routes.news}>
								<Trans i18nKey="header.secondary.gnb1" />
							</Link>
						</div>
						<div className={pathname.includes(routes.recruit) ? 'on' : ''}>
							<Link to={routes.recruit}>
								<Trans i18nKey="header.secondary.gnb2" />
							</Link>
						</div>
					</div>
				</div>
				{/* <li className={`ai-comment ${pathname.includes(routes.automaticComment) ? 'on' : ''}`}>
					<Link to={routes.automaticComment} onClick={mobileMenuClick}>
						<Trans i18nKey="header.gnb8" />
					</Link>
				</li> */}
				{/* <li className={pathname.includes(routes.contact) ? 'on' : ''}>
					<Link to={routes.contact} onClick={mobileMenuClick}>
						<Trans i18nKey="header.gnb9" />
					</Link>
				</li> */}
			</div>
		</header>
	);
};

export default Header;
