import React from 'react';
import { CharacterTit, PetInfo } from "./components/character";

const Character = () => {
	
	return (
		<>
			<section className='sct-charactor pet'>
				<article className='visual'>
					<h2>펫팔파이브</h2>
				</article>
				<article className='content'>
					<div className='pet-box'>
						<CharacterTit />
						<PetInfo />
					</div>
				</article>
			</section>
		</>
	);
};

export default Character;
