import React from "react";
import { Qwilr } from "./components/qwilr";

const Contact = () => {
  return (
    <>
      <section className='contact'>
        <article className='visual'>
          <h2>qwilr</h2>
        </article>
        <article className='content'>
          <Qwilr />
        </article>
      </section>
    </>
  );
};

export default Contact;
