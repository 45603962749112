import React, { createContext, useRef, useState } from 'react';
import i18n from './i18n';

/** 공용으로 사용할 전역 state */
export const BigglzContext = createContext({
  myLanguage: "ko",
  setMyLanguage: () => { },
  wrapClass: "",
  setWrapClass: () => { },
  contentClass: "",
  setContentClass: () => { },
  mainClass: "",
  setMainClass: () => { },
  headerRef: null,
});

/** Provider 컴포넌트 */
const ContextProvider = ({ children }) => {
  const [myLanguage, setMyLanguage] = useState(i18n.language);
  const [wrapClass, setWrapClass] = useState("");
  const [contentClass, setContentClass] = useState("");
  const [mainClass, setMainClass] = useState("");
  const headerRef = useRef(null);
  
  return (
    <BigglzContext.Provider value={{
      myLanguage: myLanguage,
      setMyLanguage: setMyLanguage,
      wrapClass: wrapClass,
      setWrapClass: setWrapClass,
      contentClass: contentClass,
      setContentClass: setContentClass,
      mainClass: mainClass,
      setMainClass: setMainClass,
      headerRef: headerRef,
    }}>
      {children}
    </BigglzContext.Provider>
  );
};

export default ContextProvider;