import React, { useContext } from "react";
import { BigglzContext } from "../../../../utils/contextProvider";
import { ENG, KOR } from "../../../../layout/layout";

export const BigglzVision = () => {
  const { myLanguage } = useContext(BigglzContext);

  return (
    <>
      <h3>
        {
          myLanguage === KOR ? (
            <>비글즈 목표</>
          ) : myLanguage === ENG ? (
            <>Bigglz&#39;s Mission</>
          ) : null
        }
      </h3>
      <p className="txt1">
        {
          myLanguage === KOR ? (
            <>
              우리는
              <span>
                <strong>따뜻하고 즐거운 서비스</strong>를 제공해요
              </span>
            </>
          ) : myLanguage === ENG ? (
            <>
              <strong>I&#39;m Your Friends, bigglz!</strong>
              <span>We provide a warm and joyful service.</span>
            </>
          ) : null
        }
      </p>
      <p className="txt2 txt-vision">
        {
          myLanguage === KOR ? (
            <>
              <strong>비글즈</strong>는 시각적 즐거움과 감정적 만족감 그리고 캐릭터 페르소나가 담긴 감성적 힐링 대화로 스트레스를 해소하고, 위로해주는 <strong>반려마음챙김</strong> 기업입니다.
            </>
          ) : myLanguage === ENG ? (
            <>
              Driven by a deep understanding of the unique mental health
              challenges faced by young people today, we are committed to
              offering a digital sanctuary (safe place) where they can find
              comfort, connection, and empowerment through caring for a
              digital pet.
            </>
          ) : null
        }
      </p>
      <div className='img'>
        <figure>
          <img src='/images/sub/img_visual01.png' alt='비글즈 건물' />
        </figure>
      </div>
      <div className="txt-area">
        <p className="txt2">
          {
            myLanguage === KOR ? (
              <>
                비글즈는 2021년 창업한 스타트업으로<br />
                모든 이들의 마음에 따스한 위로를 줄 수 있는 다양한 서비스를 제공하고 있어요.
              </>
            ) : myLanguage === ENG ? (
              <>
                ‘Bigglz’ is a startup founded in 2021<br />
                that provides a variety of services that can provide warmth comfort to everyone.
              </>
            ) : null
          }
        </p>
        <p className="txt2">
          {
            myLanguage === KOR ? (
              <>
                사람들의 일상에 따뜻함과 행복함이 가득하기를<br />
                바라는 큰 꿈을 가진 사람들이 모여 그 꿈을 실현해 나가고 있어요.
              </>
            ) : myLanguage === ENG ? (
              <>
                We have an ambitious dream of filling<br />
                everyone&#39;s heart with warmth, and happiness.
              </>
            ) : null
          }
        </p>
        <p className="txt2">
          {
            myLanguage === KOR ? (
              <>
                모바일 플랫폼 사업을 핵심으로 캐릭터 IP 사업,<br />
                오프라인 IP 기반 힐링 스페이스 사업을 진행하고 있으며<br />
                진정한 힐링 글로벌 브랜드로 도약하기 위해<br />
                타겟 특화 멘탈케어 대화형 AI 엔진 개발을 추진하고 있습니다.
              </>
            ) : myLanguage === ENG ? (
              <>
                We are establishing ourselves as a global mental wellness brand with our mobile digital pet platform (conversational AI) , character IP business and offline IPbased wellness spaces.
              </>
            ) : null
          }
        </p>
        <p className="txt2">
          {
            myLanguage === KOR ? (
              <>
                함께 위로하고 함께 즐기는 세상, 비글즈가 일상 속 행복 전달소가 되어드릴게요.
              </>
            ) : myLanguage === ENG ? (
              <>
                In a world where we comfort and enjoy together, Bigglz will become a source of happiness in everyday life.
              </>
            ) : null
          }
        </p>
      </div>
    </>
  );
}

export const CesTxt = () => {
    const { myLanguage } = useContext(BigglzContext);

    const opts = {
        width: '100%',
        height: '100%',
    }

    return (
        <div className="video-area align-flex-start">
            {
                myLanguage === KOR && (
                    <div className="txt mr-40">
                        <div className="tit">Bigglz Launches AI Mental Health App Hiing in August 2024</div>
                        <div className="desc">
                            Korean mental health technology company Bigglz has officially launched its AI-powered mental health application ‘Hiing’ (pronounced as “ha-ing”) in August 2024, initially releasing in the Korean market.<br />
                            The innovative app combines specialized chatbot technology with character-based interactions to provide mental health support for teenagers and young adults.<br />
                            The app’s standout feature is its specialized mental health chatbot, developed through extensive collaboration with mental health experts.<br />
                            What sets Hiing apart is its unique self-praise diary function, which leverages AI image generation technology to create personalized character illustrations that enhance users’ emotional well-being experience.
                        </div>
                    </div>
                )
            }
            {
                myLanguage === ENG && (
                    <div className="txt mr-40">
                        <div className="tit">Bigglz Launches AI Mental Health App Hiing in August 2024</div>
                        <div className="desc">
                            Korean mental health technology company Bigglz has officially launched its AI-powered mental health application ‘Hiing’ (pronounced as “ha-ing”) in August 2024, initially releasing in the Korean market.<br />
                            The innovative app combines specialized chatbot technology with character-based interactions to provide mental health support for teenagers and young adults.<br />
                            The app’s standout feature is its specialized mental health chatbot, developed through extensive collaboration with mental health experts.<br />
                            What sets Hiing apart is its unique self-praise diary function, which leverages AI image generation technology to create personalized character illustrations that enhance users’ emotional well-being experience.
                        </div>
                    </div>
                )
            }
            <div className="video-wrap">
                <div className="iframe-wrap" style={{ overflow: 'visible' }}>
                    <img src='/images/picture/ces_img_1.png' />
                </div>
            </div>
        </div>
    )
}

export const HistoryInfo = () => {
  const { myLanguage } = useContext(BigglzContext);

  return (
    <>
      <h3>
        {
          myLanguage === KOR ? (
            <>비글즈가 걸어온 길</>
          ) : myLanguage === ENG ? (
            <>Bigglz’s Career</>
          ) : null
        }
      </h3>
      <p className="txt1">
        {
          myLanguage === KOR ? (
            <>
              Bigglz는 오늘도 <strong>한 걸음</strong> 나아가고 있습니다.
            </>
          ) : myLanguage === ENG ? (
            <>
              Bigglz is taking another <strong>step</strong> forward today.
            </>
          ) : null
        }
      </p>
      <div className="history-area">
        <div className="year-area">
          <div className="year">2021</div>
          <div className="month-box">
            {
              myLanguage === KOR ? (
                <>
                  <div className="month-area">
                    <div className="month">05월</div>
                    <div className="cnt">
                      <span>주식회사 비글즈 설립</span>
                    </div>
                  </div>
                  <div className="month-area">
                    <div className="month">12월</div>
                    <div className="cnt">
                      <span>‘대형베이커리카페 카페gg’, ‘아트gg갤러리’ 오픈</span>
                    </div>
                  </div>
                </>
              ) : myLanguage === ENG ? (
                <>
                  <div className="month-area">
                    <div className="month">May</div>
                    <div className="cnt">
                      <span>Establishment of Bigglz Co., Ltd.</span>
                    </div>
                  </div>
                  <div className="month-area">
                    <div className="month">Dec</div>
                    <div className="cnt">
                      <span>‘Large Bakery Cafe gg’ and ‘Art gg Gallery’ opened</span>
                    </div>
                  </div>
                </>
              ) : null
            }
          </div>
        </div>
        <div className="year-area">
          <div className="year">2022</div>
          <div className="month-box">
            {
              myLanguage === KOR ? (
                <>
                  <div className="month-area">
                    <div className="month">05월</div>
                    <div className="cnt">
                      <span><strong>중소벤처기업부 주관 초기창업패키지 선정</strong></span>
                    </div>
                  </div>
                  <div className="month-area">
                    <div className="month">07월</div>
                    <div className="cnt">
                      <span><strong>국내버전 비글즈 앱 런칭</strong></span>
                      <span>(2024.08기준) 다운로드 700,000+</span>
                      <span>(2024.08) MAU 100,000+</span>
                    </div>
                  </div>
                  <div className="month-area">
                    <div className="month">12월</div>
                    <div className="cnt">
                      <span>Pre-시리즈A 15억원 투자유치</span>
                      <span>상표등록특허 24건, 디자인출원특허 5건</span>
                    </div>
                  </div>
                </>
              ) : myLanguage === ENG ? (
                <>
                  <div className="month-area">
                    <div className="month">Jul</div>
                    <div className="cnt">
                      <span>Launch of domestic version of Bigglz app</span>
                    </div>
                  </div>
                  <div className="month-area">
                    <div className="month">Dec</div>
                    <div className="cnt">
                      <span>Attracted investment of 1.5 billion won in Pre-Series A</span>
                      <span>trademark registration patents, 5 design application patents</span>
                    </div>
                  </div>
                </>
              ) : null
            }
          </div>
        </div>
        <div className="year-area">
          <div className="year">2023</div>
          <div className="month-box">
            {
              myLanguage === KOR ? (
                <>
                  <div className="month-area">
                    <div className="month">04월</div>
                    <div className="cnt">
                      <span>{`영어버전 비글즈 앱 런칭 (동남아시아 > 미국 > 브라질, 인도 순으로 런칭)`}</span>
                    </div>
                  </div>
                  <div className="month-area">
                    <div className="month">06월</div>
                    <div className="cnt">
                      <span>라스베이가스 라이선싱 엑스포 참여</span>
                    </div>
                  </div>
                  <div className="month-area">
                    <div className="month">08월</div>
                    <div className="cnt">
                      <span>기술특허출원 4종</span>
                    </div>
                  </div>
                  <div className="month-area">
                    <div className="month">10월</div>
                    <div className="cnt">
                      <span><strong>페르소나 챗봇 Agent AI 출시</strong></span>
                      <span>영국 라이선싱 엑스포 참여 (PoC : 60+)</span>
                    </div>
                  </div>
                  <div className="month-area">
                    <div className="month">11월</div>
                    <div className="cnt">
                      <span><strong>마인드가드너 심리코칭센터 MOU 체결</strong></span>
                      <span><strong>중소벤처기업부의 민간 투자주도형 기술창업 지원 프로그램 ‘딥테크 팁스(TIPS)’ 선정 및 필로소피아벤처스 3억원 투자유치</strong></span>
                      <span>국내유니콘 기업들의 글로벌진출파트너 Relevant Skills Development, Inc,(https://relevant.world/)계약 체결</span>
                    </div>
                  </div>
                </>
              ) : myLanguage === ENG ? (
                <>
                  {/* <div className="month-area">
                    <div className="month">Feb</div>
                    <div className="cnt">
                      <span>MOU signed with Lab2I Co., Ltd.</span>
                    </div>
                  </div> */}
                  <div className="month-area">
                    <div className="month">Apr</div>
                    <div className="cnt">
                      <span>{ `‘bigglz’ app launches English version  (Southeast Asia-> USA -> Brazil / India)` }</span>
                      {/* <span>Acquired recognition as a corporate research institute</span> */}
                    </div>
                  </div>
                  <div className="month-area">
                    <div className="month">Jun</div>
                    <div className="cnt">
                      <span>Participated in the Las Vegas Licensing Expo</span>
                    </div>
                  </div>
                  {/* <div className="month-area">
                    <div className="month">May</div>
                    <div className="cnt">
                      <span> Acquired the highest evaluation as a start-up company in the initial start-up package support project</span>
                      <span>Acquired women’s enterprise certification</span>
                    </div>
                  </div> */}
                  <div className="month-area">
                    <div className="month">Aug</div>
                    <div className="cnt">
                      <span>Applied for 4 technical patents</span>
                    </div>
                  </div>
                  {/* <div className="month-area">
                    <div className="month">Sep</div>
                    <div className="cnt">
                      <span>Selected as Seolgi Avatalk Ambassador</span>
                    </div>
                  </div> */}
                  <div className="month-area">
                    <div className="month">Oct</div>
                    <div className="cnt">
                      <span><strong>Developed Persona Chatbot AI technology</strong></span>
                      <span>Participated in London Licensing Expo (PoC : 60+)</span>
                    </div>
                  </div>
                  <div className="month-area">
                    <div className="month">Nov</div>
                    <div className="cnt">
                      <span><strong>Raised $220,000 from Philosopia Ventures
                      Selected for ‘Deep Tech Tips’ (a government-funded R&D program)</strong></span>
                      <span>MOU signed with Mindgardner Psychological Coaching Center</span>
                      <span>A global consulting contract with Relevant Skills Development, Inc.</span>
                    </div>
                  </div>
                </>
              ) : null
            }
          </div>
        </div>
        <div className="year-area">
          <div className="year">2024</div>
          <div className="month-box">
            {
              myLanguage === KOR ? (
                <>
                  <div className="month-area">
                    <div className="month">02월</div>
                    <div className="cnt">
                      <span><strong>청소년을 위한 힐링 멘탈 케어 상담 앱 'Hiing' 출시 (AI 기술 기반)</strong></span>
                    </div>
                  </div>
                  <div className="month-area">
                    <div className="month">04월</div>
                    <div className="cnt">
                      <span>글로벌 현지화를 위한 미국 IP 저작권 신청</span>
                    </div>
                  </div>
                  <div className="month-area">
                    <div className="month">05월</div>
                    <div className="cnt">
                      <span><strong>라스베가스 라이선싱 엑스포 독점 부스로 참가</strong></span>
                    </div>
                  </div>
                  <div className="month-area">
                    <div className="month">06월</div>
                    <div className="cnt">
                      <span><strong>딥테크 팁스 스타트업 사업화 지원 및 글로벌 엑셀러레이션 프로그램 선정</strong></span>
                    </div>
                  </div>
                  <div className="month-area">
                    <div className="month">07월</div>
                    <div className="cnt">
                      <span>벤처기업 인증</span>
                    </div>
                  </div>
                  <div className="month-area">
                    <div className="month">08월</div>
                    <div className="cnt">
                      <span><strong>IP 라이선싱 AI 굿즈 계약 체결</strong></span>
                      <span>인형 및 AI 기술 기반 인형 제조 계약 with 써드라운드</span>
                      <span><strong>CES 2025 참가 지원 사업 선정</strong></span>
                    </div>
                  </div>
                </>
              ) : myLanguage === ENG ? (
                <>
                  <div className="month-area">
                    <div className="month">Feb</div>
                    <div className="cnt">
                    <span><strong>‘Hiing’ beta launch (based on AI tech)</strong></span>
                    </div>
                  </div>
                  <div className="month-area">
                    <div className="month">Apr</div>
                    <div className="cnt">
                      <span>Completed US IP copyright application for IP global localization</span>
                    </div>
                  </div>
                  <div className="month-area">
                    <div className="month">May</div>
                    <div className="cnt">
                      <span><strong>Participated in exclusive booth at Licensing Expo in Las Vegas</strong></span>
                    </div>
                  </div>
                  <div className="month-area">
                    <div className="month">Jun</div>
                    <div className="cnt">
                      <span><strong>Selected by the Deep-Tech Tips Startup Commercialization Support Project & Global Acceleration Program</strong></span>
                    </div>
                  </div>
                  <div className="month-area">
                    <div className="month">Jul</div>
                    <div className="cnt">
                      <span>Venture Business Certification</span>
                    </div>
                  </div>
                  <div className="month-area">
                    <div className="month">Aug</div>
                    <div className="cnt">
                      <span><strong>IP licensing contract with plush toys</strong></span>
                      <span>plush toy agreements with AI technology with 3rdround company</span>
                      <span><strong>Selected as a support project for participating in CES 2025 (by Daejeon City)</strong></span>
                      <span><strong>Healing mental care counseling app for teens ‘Hiing’ launch (based on AI tech)</strong></span>
                    </div>
                  </div>
                </>
              ) : null
            }
          </div>
        </div>
      </div>
    </>
  );
}

const partnerList1 = ['sports2i', 'lab2ai', 'darmCulture', 'mindguardner', 'relevant'];
const partnerList2 = ['google', 'gowell', 'bespinGlobal', 'datePop', 'dongram', '2bytes', 'soulBrain', 'materialsPark', 'furato', 'life'];
const partnerList3 = ['zenworks', 'philosophiaVentures', 'joshuaPartners', 'eCapitanlnet'];

export const ComPartner = () => {
  const { myLanguage } = useContext(BigglzContext);

  return (
    <>
      <h3>
        {
          myLanguage === KOR ? (
            <>
              비글즈 협력사
            </>
          ) : myLanguage === ENG ? (
            <>
              Bigglz Partner Company
            </>
          ) : null
        }
      </h3>
      <p className="txt1">

        {
          myLanguage === KOR ? (
            <>
              국내외 주요 업체들과<br />
              <strong>협력하며</strong> 서비스를 키워나가고 있습니다.
            </>
          ) : myLanguage === ENG ? (
            <>
              We are developing our services by
              <span><strong>collaborating</strong> with major domestic and foreign companies.</span>
            </>
          ) : null
        }
      </p>
      <div className="partner-area">
        <div className="tag">
          {
            myLanguage === KOR ? (
              <>
                # 주요 협력 파트너
              </>
            ) : myLanguage === ENG ? (
              <>
                # Main cooperation partner
              </>
            ) : null
          }
        </div>
        <ul className="partner-list">
          {
            partnerList1.map((value, index) => (
              <li key={index}>
                <img src={`/images/partner/com_${value}.png`} alt={value} />
              </li>
            ))
          }
        </ul>
        <div className="tag">
          {
            myLanguage === KOR ? (
              <>
                # 캐릭터 IP 및 온오프라인 파트너
              </>
            ) : myLanguage === ENG ? (
              <>
                # Character IP & On/offline partner
              </>
            ) : null
          }
        </div>
        <ul className="partner-list">
          {
            partnerList2.map((value, index) => (
              <li key={index}>
                <img src={`/images/partner/com_${value}.png`} alt={value} />
              </li>
            ))
          }
        </ul>
        <div className="tag">
          {
            myLanguage === KOR ? (
              <>
                # 투자사 및 글로벌 파트너
              </>
            ) : myLanguage === ENG ? (
              <>
                # Investment & Global partner
              </>
            ) : null
          }
        </div>
        <ul className="partner-list">
          {
            partnerList3.map((value, index) => (
              <li key={index}>
                <img src={`/images/partner/com_${value}.png`} alt={value} />
              </li>
            ))
          }
        </ul>
      </div>
    </>
  );
}