import React from "react";

export const Qwilr = () => {
  return (
    <iframe
      src="https://test11111.qwilr.com/Product-Proposal-rjtZoxdfS7iQ"
      style={{ width: '100%', height: '100%', border: 'none' }}
      title="Embedded Page"
    />
  )
}

export default Qwilr;