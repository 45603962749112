import React, { useContext, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { routes } from '../routes/routes'
import { useTranslation } from "react-i18next";
import { BigglzContext } from "../utils/contextProvider";
import Footer from "../components/common/footer";
import Header from "../components/common/header";
import ScrollTop from "../components/common/scrollTop";

export const ENG = "en";
export const KOR = "ko";

const Layout = ({ children }) => {
	const { pathname } = useLocation();
	const { replace } = useHistory();
	const { i18n } = useTranslation();
	const { wrapClass, contentClass } = useContext(BigglzContext);

	/** 디폴트 페이지를 /service 페이지로 */
	useEffect(() => {
		const DETAULT_PATHNAME = routes.service;
		if (pathname === '/') replace(DETAULT_PATHNAME);
	}, [])


  	// /** 한국어 셋팅 */
	// useEffect(() => {
	// 	const browserLanguage = i18n.language;
    
	// 	if (browserLanguage === "ko") {
	// 		i18n.changeLanguage("ko");
	// 	}
	// }, [i18n]);

	return (
		<div id='wrap' className={wrapClass}>
			<div id='container' className={i18n.language === KOR ? "KOR" : "ENG"}>
				<div id='content' className={contentClass}>
					<Header />
					<ScrollTop />
					{children}
					<Footer />
				</div>
			</div>
		</div>
	);
};

export default Layout;
