import React, { useState, useEffect, useRef, useContext } from "react";
import { BigglzContext } from "../../../../utils/contextProvider";
import { ENG, KOR } from "../../../../layout/layout";

export const CharacterTit = () => {
  const { myLanguage } = useContext(BigglzContext);

  return (
    <div className="txt-box">
      <h3>
        {
          myLanguage === KOR ? (
            <>
              비글즈의
              <span> <strong>귀여운 캐릭터들</strong>을</span>
              <span> 소개할게요!</span>
            </>
          ) : myLanguage === ENG ? (
            <>
              An introduction
              <span> to <strong>the characters of Bigglz</strong></span>
            </>
          ) : null
        }
      </h3>
      <p>
        {
          myLanguage === KOR ? (
            <>
              비글즈는 다양한 캐릭터 IP를 보유하고 있어요.
              <span> 각자의 개성이 뚜렷한 ‘탄이’, ‘설기’, ‘레오’, ‘망고’, ‘레이’와 펫팔파이브 멤버</span>
              <span> 따뜻한 마음씨를 가진 포그니까지 만나보세요.</span>
            </>
          ) : myLanguage === ENG ? (
            <>
              Bigglz offers a range of character IPs. Meet the Pet Pal Five members ‘Tani,’ ‘Sulgi,’ ‘Leo,’ ‘Mango,’ and ‘Ray,’ who each have distinct personalities, as well as the warm-hearted Pogny.
            </>
          ) : null
        }
      </p>
    </div>
  );
}

const CharacterInfo = (props) => {
  const { data } = props;
  const [decoView, setDecoView] = useState("");
  const petListRef = useRef(null);
  const { headerRef } = useContext(BigglzContext);

  useEffect(() => {
    /** 캐릭터 꾸밈요소 스크롤 이벤트 */
    const handleScroll = () => {
      const conditionBefore = (petListRef.current.getBoundingClientRect().top - headerRef.current.clientHeight * 3) <= 0;
      const conditionAfter = petListRef.current.getBoundingClientRect().bottom < 0;

      setDecoView(conditionBefore && !conditionAfter ? "on" : "");
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, [headerRef]);

  return (
    <li className={`${data.value} ${decoView}`} ref={petListRef} index={data.index}>
      <div className="img">
        <div className='kind'>{data.petKind}</div>
        <figure>
          <img src={`/images/character/img_${data.value}.png`} alt={data.nameType1} />
        </figure>
        <span className='object deco1'></span>
        <span className='object deco2'></span>
        <span className='object deco3'></span>
      </div>
      <div className='info'>
        <div className='name'>
          {data.nameType1} <span>{data.nameType2}</span>
        </div>
        <ul className='simple'>
          <li>{data.personality}</li>
          <li>{data.hashTag1}</li>
          <li>{data.hashTag2}</li>
        </ul>
        <div className='txt'>{data.discription}</div>
      </div>
    </li>
  );
}

const list = [
  {
    Korean: {
      index: 0,
      value: "tani",
      petKind: "Beagle",
      nameType1: "탄이",
      nameType2: "TANI",
      personality: "ENFP",
      hashTag1: "#열정",
      hashTag2: "#스포츠",
      discription: "뛰어난 후각으로 토끼사냥을 주로하는 탄탄한 근육질 몸의 소유자. 현재 탄이의 오랜 꿈이었던 경찰견이 되고자 시험을 보는 중이라고 함. 영리하긴 한데(?) 이상하게 손이 많이 가는 스타일."
    },
    english: {
      index: 0,
      value: "tani",
      petKind: "Beagle",
      nameType1: "TANI",
      nameType2: "TANI",
      personality: "ENFP",
      hashTag1: "#Passion",
      hashTag2: "#Sports",
      discription: "He has a strong muscular body, and hunts rabbits with a exceptional sense of smell. He is working on an exam to be a police which has been his long-cherished dream. Very clever but takes a lot of care."
    }
  },
  {
    Korean: {
      index: 1,
      value: "sulgi",
      petKind: "Pomeranian",
      nameType1: "설기",
      nameType2: "SULGI",
      personality: "ISFP",
      hashTag1: "#아이돌",
      hashTag2: "#냠냠박사",
      discription: "호기심이 많고 자기애가 강한 새하얀 포메라니안. 북극에서 썰매를 끌던 개들의 후손으로 곧 큰 개가 될 수 있다고 믿고 있음. 꾸미기를 좋아해서 매일 털관리를 하고 365일 다이어트 중. 하지만 먹는 것을 포기하진 못함."
    },
    english: {
      index: 1,
      value: "sulgi",
      petKind: "Pomeranian",
      nameType1: "SULGI",
      nameType2: "SULGI",
      personality: "ISFP",
      hashTag1: "#Idol",
      hashTag2: "#Dr. yum yum",
      discription: "She is a pomeranian who’s full of curiosity and self esteem. Decendent of sledding dogs in the north pole and she belives one day she could get big. She’s taking care of her fur everyday, and trying to be in shape as she likes to doll herself up. Yet still can’t give up on eating."
    }
  },
  {
    Korean: {
      index: 2,
      value: "leo",
      petKind: "Korean shorthair",
      nameType1: "레오",
      nameType2: "LEO",
      personality: "ENTP",
      hashTag1: "#삼냥즈리더",
      hashTag2: "#패션",
      discription: "OMG삼냥즈의 리더이자 두뇌파 행동 대장. 망고와 레이 가운데에서 이야기를 조율해주는 멋짐폭발 리더. 냥이계의 브레인으로 명석한 만능 재주꾼. 트렌드에 민감한 패션리더로 삼냥즈의 세련미를 담당"
    },
    english: {
      index: 2,
      value: "leo",
      petKind: "Korean shorthair",
      nameType1: "LEO",
      nameType2: "LEO",
      personality: "ENTP",
      hashTag1: "#Leader of kitty trio",
      hashTag2: "#Fashion",
      discription: "She is the leader of OMG kitty trio, with brain and swift action. The cool leader who coordinates the stories between Mango and Ray. Brain of kitty world with brightness and talent. The fashion leader who’s very sensitive to trends, so she’s always in charge of kitty trio’s refinement."
    }
  },
  {
    Korean: {
      index: 3,
      value: "mango",
      petKind: "Korean shorthair",
      nameType1: "망고",
      nameType2: "MANGO",
      personality: "ENTJ",
      hashTag1: "#힙스터",
      hashTag2: "#핵인싸",
      discription: "OMG삼냥즈의 둘째. 삼냥즈의 분위기메이커이자 넘치는 꾸러기 기질로 냥이계의 핵인싸. 힙한 감성에 푹 빠진 미래의 뮤지션. 망고의 사생활은 아직 비밀에 쌓여있다."
    },
    english: {
      index: 3,
      value: "mango",
      petKind: "Korean shorthair",
      nameType1: "MANGO",
      nameType2: "MANGO",
      personality: "ENTJ",
      hashTag1: "#Hipster",
      hashTag2: "#Super insider",
      discription: "The second oldest of OMG kitty trio. The entertainer of kitty trio, and super insider of kitty world with mischievous temperament. Future musician, full of hip hop temperament. Mango’s private life is still in veil."
    }
  },
  {
    Korean: {
      index: 4,
      value: "ray",
      petKind: "Korean shorthair",
      nameType1: "레이",
      nameType2: "RAY",
      personality: "INFP",
      hashTag1: "#핑크사랑",
      hashTag2: "#막내온탑",
      discription: "순하고 겁이 많은 마음 여린 막내 냥이. OMG삼냥즈에서 귀여움을 담당하는 마스코트. 향기롭고 예쁜 것을 좋아하는 핑크러버. 수많은 냥이들의 인기를 독차지 하고 있는 사랑의 폭격기 막내지만 알고보면 삼냥즈의 실세일지도?"
    },
    english: {
      index: 4,
      value: "ray",
      petKind: "Korean shorthair",
      nameType1: "RAY",
      nameType2: "RAY",
      personality: "INFP",
      hashTag1: "#Pink lover",
      hashTag2: "#The youngest on top",
      discription: "The gentle, timid youngest kitty. The symbol of OMG kitty trio who’s in charge of cuteness. A pink lover who likes fragrant and pretty things. The youngest love bomber who’s taking all the kittys’ popularity, but she may be the real leading member of the kitty trio."
    }
  },
  {
    Korean: {
      index: 5,
      value: "pogny",
      petKind: "White bear",
      nameType1: "포그니",
      nameType2: "POGNY",
      personality: "LOVE",
      hashTag1: "#큰귀작은입",
      hashTag2: "#따뜻한백곰",
      discription: "큰 귀와 작은 입을 가지고 있어서, 말하는 것보다 듣는 걸 더 좋아한다. 모두가 행복하고, 행운이 가득한 생활을 하길 바라는 따뜻한 친구다."
    },
    english: {
      index: 5,
      value: "pogny",
      petKind: "White bear",
      nameType1: "POGNY",
      nameType2: "POGNY",
      personality: "LOVE",
      hashTag1: "#Big ear with small lips",
      hashTag2: "#Warm Heart",
      discription: "Pogny has big ears and a small mouth, so she prefers listening then talking. Pogny is a warm friend who wants everyone to live a happy, lucky life."
    }
  },
]

export const PetInfo = () => {
  const { myLanguage } = useContext(BigglzContext);

  return (
    <ul className='pet-list'>
      {list.map((value, index) => (
        <CharacterInfo
          key={index}
          data={myLanguage === KOR ? value.Korean : value.english}
        />
      ))}
    </ul>
  );
}