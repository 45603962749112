import React, { useState, useEffect, useRef, useContext } from "react";
import { Trans } from "react-i18next";
import { BigglzContext } from "../../../utils/contextProvider";
import { ENG, KOR } from "../../../layout/layout";
import { AppInfo, TechnologyInfo } from "./components/persona";

const Persona = () => {
  const { myLanguage, headerRef } = useContext(BigglzContext);
  const [activeTab, setActiveTab] = useState('appInfo');
  const [tabFix, setTabFix] = useState("");
  const [visualHeight, setVisualHeight] = useState(null);
  const visualRef = useRef(null);
  const contentRef = useRef(null);
  const tabRef = useRef(null);

  /** 모바일 기기 해상도 적용 범위 */
  useEffect(() => {
    /** 사이즈 체크 이벤트 */
    const onLoad = () => {
      // setVisualHeight(`${window.innerHeight}` - `${headerRef.current.clientHeight}` + "px");
    }

    /** 스크롤 시 탭이 최상단에 걸치는 이벤트 */
    const handleTabFix = () => {
      if (contentRef.current.getBoundingClientRect().top <= 0) {
        setTabFix("fix");
      } else {
        setTabFix("");
      }
    }

    /** 모바일 visual flexible */
    const isMobileScroll = () => {
      if (visualRef.current && headerRef.current) {
        if (visualRef.current.getBoundingClientRect().top <= 0) {
          setVisualHeight(`calc(100vh - ${headerRef.current.clientHeight}px)`);
        } else {
          setVisualHeight(`${window.innerHeight}` - `${headerRef.current.clientHeight}` + "px");
        }
      }
    }

    window.addEventListener("scroll", handleTabFix);
    window.addEventListener("resize", handleTabFix);

    if (tabRef && window.matchMedia("(max-width:760px)").matches) {
      window.addEventListener("load", onLoad);
      // window.addEventListener("scroll", isMobileScroll);
      // window.addEventListener("resize", isMobileScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleTabFix);
      window.removeEventListener("resize", handleTabFix);
      // window.removeEventListener("scroll", isMobileScroll);
      // window.removeEventListener("resize", isMobileScroll);
    }
  }, [headerRef, visualHeight]);

  /** 탭을 변경 이벤트 */
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  }

  /** 모바일 내 스크롤 버튼 클릭 이벤트 */
  const handleScroll = () => {
    // setVisualHeight(`calc(100vh - ${headerRef.current.clientHeight}px)`);
    // setTimeout(() => {
    //   contentRef.current.scrollIntoView({ behavior: "smooth" });
    // }, 10);

    const scrollYset = tabRef.current.getBoundingClientRect().top + window.pageYOffset;
    window.scrollTo({ top: scrollYset });
  }
  // AITechnology_img_3
  // activeTab === "technologyInfo"
  return (
    <>
      <section className="persona">
        <article
          ref={visualRef}
          style={{ height: visualHeight }}
          className={`visual ${ activeTab === 'technologyInfo' ? 'bg-technology' : '' }`}
        >
          {
            activeTab !== 'technologyInfo' &&
            <div className="visual-area">
              <div className="img-visual">
                {
                  myLanguage === KOR ? (
                    <>
                      <img src="/images/sub/visual_hiing.png" alt="" />
                    </>
                  ) : myLanguage === ENG ? (
                    <>
                      <img src="/images/eng/eng_visual_hiing.png" alt="" />
                    </>
                  ) : null
                }
              </div>
              <div className="tit-area">
                <h2>
                  <img src="/images/sub/ces_Logo.png" alt="ces" />
                </h2>
                <div className="tit">
                  {
                    myLanguage === KOR ? (
                      <>
                        <span>나만의</span>
                        <span>
                          특별한 친구, <strong>하잉</strong>
                        </span>
                      </>
                    ) : myLanguage === ENG ? (
                      <>
                        <div className="app-icon-images">
                          <div className="app-icon"><img src="/images/sub/hiing-app-icon.png" alt="" /></div>
                          <div className="app-text"><img src="/images/sub/tit_hiing.png" alt="" /></div>
                        </div>
                        <span>my special AI friend</span>
                      </>
                    ) : null
                  }
                </div>
                <div className="txt">
                  {
                    myLanguage === KOR ? (
                      <>
                        <span>감동과 공감이 깃든</span>
                        <span>특별한 대화를 나눠보세요.</span>
                      </>
                    ) : myLanguage === ENG ? (
                      <>
                        <span>Have a special</span>
                        <span>conversation filled with</span>
                        <span>emotion and empathy.</span>
                      </>
                    ) : null
                  }
                </div>
              </div>
            </div>
          }
          
          <button
            className="scroller"
            onClick={handleScroll}
          >
            scroll
          </button>
        </article>
        <article className="content" ref={contentRef}>
          <div
            className={`tab ${tabFix}`}
            ref={tabRef}
          >
            <ul>
              <li
                className={activeTab === "appInfo" ? "on" : ""}
                onClick={() => handleTabClick("appInfo")}
              >
                {
                  myLanguage === KOR ? (
                    <>
                      하잉 앱
                    </>
                  ) : myLanguage === ENG ? (
                    <>
                      Hiing app
                    </>
                  ) : null
                }
              </li>
              <li
                className={activeTab === "technologyInfo" ? "on" : ""}
                onClick={() => handleTabClick("technologyInfo")}
              >
                {
                  myLanguage === KOR ? (
                    <>
                      기술소개
                    </>
                  ) : myLanguage === ENG ? (
                    <>
                      <span>AI Technology</span>
                    </>
                  ) : null
                }
              </li>
            </ul>
          </div>
          <div className={`app-info ${activeTab === "appInfo" ? "on" : ""}`}>
            {
              activeTab === "appInfo" && (
                <AppInfo />
              )
            }
          </div>
          <div className={`technology-info ${activeTab === "technologyInfo" ? "on" : ""}`}>
            {
              activeTab === "technologyInfo" && (
                <TechnologyInfo />
              )
            }
          </div>
          <div className="contact-us">
            <div className="contact-area">
              <h3>Contact Us</h3>
              <ul className="info-list">
                <li>
                  <div className="icon"><img src="/images/sub/icon_call.png" alt="" /></div>
                  <span>010.7618.5339</span>
                </li>
                <li>
                  <div className="icon"><img src="/images/sub/icon_mail.png" alt="" /></div>
                  <span>contact@bigglz.com</span>
                </li>
              </ul>
            </div>
          </div>
        </article>
      </section>
    </>
  );
};

export default Persona;
