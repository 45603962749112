import React from 'react';
import { Switch } from 'react-router';
import { routes, components } from './routes/routes';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRoute from './layout/AppRoute';
import Layout from './layout/layout';
import ContextProvider from './utils/contextProvider';
import './utils/i18n';

const App = () => {
	return (
		<ContextProvider>
			<Router>
				<Layout>
					<Switch>
						<AppRoute path={routes.home} component={components.home} exact={true} />
						<AppRoute path={routes.about} component={components.about} />
						<AppRoute path={routes.service} component={components.service} />
						<AppRoute path={routes.news} component={components.news} />
						<AppRoute path={routes.recruit} component={components.recruit} />
						<AppRoute path={routes.character} component={components.character} />
						<AppRoute path={routes.persona} component={components.persona} />
						<AppRoute path={routes.automaticComment} component={components.automaticComment} />
						<AppRoute path={routes.contact} component={components.contact} />
						<AppRoute path='*' />
					</Switch>
				</Layout>
			</Router>
		</ContextProvider>
	);
};

export default App;
