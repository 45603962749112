import React, { useEffect, useRef, useContext } from "react";
import Swiper, { Autoplay, EffectCoverflow } from "swiper";
import { outLinks } from "../../../../routes/routes";
import { BigglzContext } from "../../../../utils/contextProvider";
import { ENG, KOR } from "../../../../layout/layout";
Swiper.use([Autoplay, EffectCoverflow]);

export const Part1 = () => {
  const { myLanguage } = useContext(BigglzContext);

  return (
    <div className='intro'>
      <h2>
        {
          myLanguage === KOR ? (
            <>
              비글즈가 당신의
              <span>
                <strong>단짝</strong>이 되어드릴게요
              </span>
            </>
          ) : myLanguage === ENG ? (
            <>
              Bigglz is here for you
              <span>to be your <strong>Best Pal</strong></span>
            </>
          ) : null
        }
      </h2>
      <div className='txt'>
        {
          myLanguage === KOR ? (
            <>
              #펫바타 #나만을위한 댕냥이 #사랑해요
            </>
          ) : myLanguage === ENG ? (
            <>
              #petvatar #pet-for-me #lovely
            </>
          ) : null
        }
      </div>
    </div>
  );
}

export const Part2 = () => {
  const { myLanguage } = useContext(BigglzContext);
	const part2Swiper = useRef(null);

  /** 테마월드, 펫룸꾸미기, 다이어리꾸미기 스와이프 */
  useEffect(() => {
    part2Swiper.current = new Swiper('.part2 .swiper-container', {
      effect: "coverflow",
      centeredSlides: true,
      allowTouchMove: false,
      loop: true,
      autoplay: {
        delay: 3440,
        disableOnInteraction: true,
      },
      slidesPerView: 'auto',
      breakpoints: {
        100: { // ~ 모바일
          coverflowEffect: {
            rotate: 0,
            stretch: 30,
            depth: 900,
            modifier: 1,
          },
        },
        768: { // 모바일 ~ 테블릿
          coverflowEffect: {
            rotate: 0,
            stretch: 0,
            depth: 800,
            modifier: 1,
          },
        },
        1080: { // ~ PC
          coverflowEffect: {
            rotate: 0,
            stretch: 102,
            depth: 320,
            modifier: 1,
          },
        },
      }
    });

		return (() => {
      part2Swiper.current && part2Swiper.current.destroy();
		});
	}, []);

  return (
    <div className='introduce'>
      <div className='txt-box'>
        <h2>
          {
            myLanguage === KOR ? (
              <>
                <span>우리의</span>
                <span>펫과 함께</span>
                <span>
                  <strong>시간을 보내는</strong>
                </span>
              </>
            ) : myLanguage === ENG ? (
              <>
                <span>Play,</span>
                <span>
                  <strong>decorate</strong> with
                </span>
                <span>your pet</span>
              </>
            ) : null
          }
        </h2>
        <div className='txt'>
          {
            myLanguage === KOR ? (
              <>
                <span>#테마월드</span>
                <span>#펫룸꾸미기</span>
                <span>#다이어리꾸미기</span>
              </>
            ) : myLanguage === ENG ? (
              <>
                <span>#petword</span>
                <span>#Decorating pet room</span>
                <span>#Decorating diary</span>
              </>
            ) : null
          }
        </div>
      </div>
      <div className='video-box'>
        <div className='swiper-container'>
          <ul className='swiper-wrapper'>
            <li className='swiper-slide img1'>
              <img src='/images/main/img_part2_1.png' alt='part2_1' />
              <img src='/images/main/part2_1.gif' className="img-gif" alt="댕냥룸꾸미기" />
            </li>
            <li className='swiper-slide img2'>
              <img src='/images/main/img_part2_2.png' alt='part2_2' />
              <img src='/images/main/part2_2.gif' className="img-gif" alt="다이어리꾸미기" />
            </li>
            <li className='swiper-slide img3'>
              <img src='/images/main/img_part2_3.png' alt='part2_3' />
              <img src='/images/main/part2_3.gif' className="img-gif" alt="펫월드" />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export const Part3 = () => {
  const { myLanguage } = useContext(BigglzContext);

  return (
    <>
      {
        myLanguage === KOR ? (
          <div className='introduce'>
            <div className='txt-box'>
              <h2>
                펫을 좋아하는
                <span>사람들이</span>
                <span>
                  <strong>소통</strong>하는
                </span>
              </h2>
              <div className='txt'>
                <span>#펫일상공유</span>
                <span>#친구와 편지 주고받기</span>
              </div>
            </div>
            <div className='video-box'>
              <ul>
                <li>
                  <img src='/images/main/img_part3_1.png' alt='펫모임만들기' />
                </li>
                <li>
                  <img src='/images/main/part3_3.gif' alt='친구와 편지 주고받기' />
                </li>
                <li>
                  <img src='/images/main/part3_2.gif' alt='펫일상공유' />
                </li>
              </ul>
            </div>
            </div>
        ) : myLanguage === ENG ? (
          <div className='introduce dp-none'></div>
        ) : null
      }
    </>
  );
}

export const Part4 = () => {
  const { myLanguage } = useContext(BigglzContext);

  return (
    <div className="introduce">
      <div className='txt-box'>
        <h2>
          {
            myLanguage === KOR ? (
              <>
                <span>
                  <strong>펫정보</strong>가
                </span>
                <span>필요한</span>
                <span>펫펨족을 위한</span>
              </>
            ) : myLanguage === ENG ? (
              <>
                <span>For pet lovers</span>
                <span>who need</span>
                <span><strong>pet information</strong></span>
              </>
            ) : null
          }
        </h2>
        <div className='txt'>
          {
            myLanguage === KOR ? (
              <>
                <span>#잡다한 펫지식쌓기</span>
                <span>#펫시설을 보다 쉽게</span>
              </>
            ) : myLanguage === ENG ? (
              <>
                <span>#Gathering a variety of pet information</span>
                <span>#Search pet facilities more easily</span>
              </>
            ) : null
          }
        </div>
      </div>
      <div className='video-box'>
        {
          myLanguage === KOR ? (
            <ul>
              <li>
                <img src='/images/main/img_part4.png' alt='잡다한 펫지식 쌓기' />
              </li>
              <li>
                <img src='/images/main/part4_2.gif' alt='펫시설을 보다 쉽게' />
              </li>
            </ul>
          ) : myLanguage === ENG ? (
            <ul>
              <li>
                <img src='/images/main/img_part4.png' alt='잡다한 펫지식 쌓기' />
              </li>
              <li>
                <img src='/images/main/part4_2.gif' alt='펫시설을 보다 쉽게' />
              </li>
            </ul>
          ) : null
        }
      </div>
    </div>
  );
}

export const Part5 = () => {
  const { myLanguage } = useContext(BigglzContext);

  return (
    <h2>
      {
        myLanguage === KOR ? (
          <>
            당신의 <strong>가상펫</strong>과 함께하는 <strong>모바일 힐링 여행</strong>
          </>
        ) : myLanguage === ENG ? (
          <>
            <strong>Mobile healing journey</strong> with your <strong>Virtual pet</strong>
          </>
        ) : null
      }
    </h2>
  );
}

export const Hiing = () => {
  const { myLanguage } = useContext(BigglzContext);

  return (
    <div className='introduce'>
      {
        myLanguage === KOR ? (
          <>
            <div className="photo">
              <figure>
                <img src="/images/main/img_hiing.png" alt="hiing" />
              </figure>
            </div>
            <div className='txt-box'>
              <h2>
                <span>비글즈에서 새로운</span>
                <span>
                  <strong>앱서비스</strong>를 출시했어요!
                </span>
              </h2>
              <div className="cnt">
                <span>페르소나 Agent AI가 녹여진 ‘하잉’앱이 출시되었어요.</span>
                <span>차별화된 sLLM을 통해 마음을 케어해주고 힐링하는 </span>
                <span>대화에 특화된 ‘하잉’을 만나보세요.</span>
              </div>
              <div className="txt">
                <span>#웹/모바일 서비스</span>
                <span>#나의 감정을 알아주는 AI</span>
                <span>#힐링&마음챙김</span>
              </div>
            </div>
          </>
          ) : myLanguage === ENG ? (
            <>
              <div className="photo">
                <figure>
                  <img src="/images/main/img_hiing.png" alt="hiing" />
                </figure>
              </div>
              <div className='txt-box'>
                <h2>
                  <span>Bigglz has launched</span>
                  <span>
                    a <strong>new service!</strong>
                  </span>
                </h2>
                <div className="cnt">
                  <span>Expanding services through Persona</span>
                  <span>Agent AI technology and establishing </span>
                  <span>a specialized sLLM for healing & counseling!</span>
                </div>
                <div className="txt">
                  <span>#Web/Mobile Service</span>
                  <span>#Emotional Conversation AI</span>
                  <span>#Healing & Counseling</span>
                </div>
              </div>
            </>
          ) : null
      }
    </div>
  );
}

export const Part6AppLinkInfo = () => {
  const { myLanguage } = useContext(BigglzContext);

  return (
    <div className="app-area">
      <div className="app-link bigglz">
        <div className="app-info">
          <div className='photo'>
            <figure>
              {
                myLanguage === KOR ? (
                  <>
                    <img src='/images/sub/ic_app01.png' alt='bigglz app' />
                  </>
                ) : myLanguage === ENG ? (
                  <>
                    <img src='/images/eng/eng_ic_app01.png' alt='bigglz app' />
                  </>
                ) : null
              }
            </figure>
          </div>
          <dl>
            {
              myLanguage === KOR ? (
                <>
                  <dt>비글즈 앱</dt>
                  <dd>가상펫 양육 어플리케이션</dd>
                </>
              ) : myLanguage === ENG ? (
                <>
                  <dt>Bigglz App</dt>
                  <dd>Virtual pet raising application</dd>
                </>
              ) : null
            }
          </dl>
        </div>
        <div className='btn-area'>
          <a href={outLinks.appleStore} target='_blank' rel="noopener noreferrer" className="btn apple">
            <i></i>App Store
          </a>
          <a href={outLinks.googlePlay} target='_blank' rel="noopener noreferrer" className="btn google">
            <i></i>Play Store
          </a>
        </div>
      </div>
      <div className="app-link hiing">
        <div className="app-info">
          <div className='photo'>
            <figure>
              <img src='/images/sub/ic_hiing.png' alt='hiing app' />
            </figure>
          </div>
          <dl>
            {
              myLanguage === KOR ? (
                <>
                  <dt>하잉 앱</dt>
                  <dd>나만의 특별한 친구와 함께하는 힐링 대화앱</dd>
                </>
              ) : myLanguage === ENG ? (
                <>
                  <dt>Hiing App</dt>
                  <dd>Have healing conversation  with your own special </dd>
                </>
              ) : null
            }
          </dl>
        </div>
        <div className='btn-area'>
          <a href={outLinks.hiingAppStore} target='_blank' rel="noopener noreferrer" className="btn apple">
            <i></i>App Store
          </a>
          <a href={outLinks.hiingGoogleStore} target='_blank' rel="noopener noreferrer" className="btn google">
            <i></i>Play Store
          </a>
        </div>
      </div>
    </div>
  );
}