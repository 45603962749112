import React from "react";
import {
  BigglzRecruitTit,
  BigglzIntroduce1,
  BigglzIntroduce2,
  BigglzIntroduce3,
  RecruitProcedure,
  RecruitRegister
} from "./components/recruit";
import { RecruitList } from "./components/recruitPosition";
const Recruit = () => {
	return (
		<>
			<section className='recruit'>
				<article className='content'>
					<BigglzRecruitTit />
					<div className="recruit-box intro1">
						<BigglzIntroduce1 />
					</div>
					<div className="recruit-box intro2">
						<BigglzIntroduce2 />
					</div>
					<div className="recruit-box intro3">
						<BigglzIntroduce3 />
					</div>
					<div className='recruit-box job'>
						<RecruitList />
					</div>
					<div className='recruit-box'>
						<RecruitProcedure />
					</div>
					<div className='recruit-box'>
						<RecruitRegister />
					</div>
				</article>
			</section>
		</>
	);
};

export default Recruit;
