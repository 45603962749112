import React, { useState, useRef, useEffect, useContext } from "react";
import { BigglzContext } from "../../../utils/contextProvider";
import { ENG, KOR } from "../../../layout/layout";
import Swiper, { Navigation, Pagination, Autoplay, EffectFade, EffectCoverflow } from "swiper";
import Advert from "../../common/advert";
import { 
  VisualPetRoom,
  VisualPetWorld,
  VisualPetCrew,
  VisualPetDiary,
  VisualPetPal
 } from "./components/visual";
import {
  Part1,
  Part2,
  Hiing,
  Part3,
  Part4,
  Part5,
  Part6AppLinkInfo
} from "./components/part";

Swiper.use([Navigation, Pagination, Autoplay, EffectFade, EffectCoverflow]);
const bulletList = ['pet1', 'pet2', 'pet3', 'pet4', 'pet5', 'pet6'];
const bullet = ['댕냥룸', '펫월드', '펫크루', '펫다이어리', '펫팔', '펫라이프'];

const MainIndex = () => {
  const { myLanguage, headerRef, mainClass } = useContext(BigglzContext);
  const [adDisabled, setAdDisabled] = useState(localStorage.getItem("homeAd") || false);
  const [quickApp, setQuickApp] = useState(false);
  const swipeRef = useRef();
  const decoRef = useRef();
  const introSwiperRef = useRef(null);
  const [countNum, setCountNum] = useState(1);
  const floatingLinkRef = useRef(null);
  const part5Ref = useRef(null);
  const [mobileBullet, setMobileBullet] = useState("");

  /** 광고 하루 보지 않기 */
  useEffect(() => {
    const nowTime = new Date().getTime();
    if (Number(localStorage.getItem("homeAdExpire")) < nowTime) {
      localStorage.removeItem("homeAdExpire");
      localStorage.removeItem("homeAd");
      setAdDisabled(false);
    }
  }, []);

  /** 메인 스와이프 슬라이드 */
  useEffect(() => {
    introSwiperRef.current = new Swiper('#intro-swiper', {
      effect: 'fade',
      centeredSlides: true,
      // allowTouchMove: false,
      loop: true,
      slidesPerView: 1,
      autoplay: {
        delay: 5000,
        disableOnInteraction: true,
      },
      pagination: {
        el: '.swiper-pagination-visual',
        clickable: true,
        renderBullet: function (idx, className) {
          return '<div class="' + className + '">' + bullet[idx] + '</div>';
        },
      },
      on: {
        activeIndexChange: function (swiper) {
          setCountNum(swiper.realIndex + 1);

          if (decoRef.current !== undefined) {
            for (let i = 0; i < bulletList.length; i++) {
              if (i === this.realIndex) {
                setMobileBullet(`${bulletList[i]}`);
              }
            }
          }
        }
      }
    });
    
    return () => {
      introSwiperRef && introSwiperRef.current.destroy();
    }
  }, [myLanguage]);

  useEffect(() => {
    /** part5 이미지 스크롤 이벤트 */
    const handleScroll = () => {
      let percent = Math.abs((part5Ref.current.getBoundingClientRect().top / 10) - headerRef.current.clientHeight);

      if (part5Ref.current.offsetTop - (part5Ref.current.clientHeight * 5) <= window.scrollY) {
        part5Ref.current.style.backgroundPosition = "50%" + percent + "%";
      }
      if (percent >= 100) {
        part5Ref.current.style.backgroundPosition = `50% 100%`;
      }

      if (0 >= part5Ref.current.getBoundingClientRect().top - headerRef.current.clientHeight) {
        setQuickApp(true);
      } else {
        setQuickApp(false);
      }
    }

    window.addEventListener("scroll", handleScroll);

    return (() => {
      introSwiperRef.current && introSwiperRef.current.destroy();
      window.removeEventListener("scroll", handleScroll);
    });
  }, [part5Ref, headerRef]);

  /** 플로팅 앱 아이콘 클릭 이벤트 */
  const appDownLoad = () => {
    floatingLinkRef.current.scrollIntoView({ block: "start" });
  }

  return (
    <>
      <section className={'main ' + mainClass} ref={swipeRef}>
        {/* {!adDisabled && <Advert adDisabled={adDisabled} setAdDisabled={setAdDisabled} />} */}
        <article className='visual'>
          <div className='app-intro'>
            <div className='swiper-container' id="intro-swiper">
              <ul className='swiper-wrapper'>
                <li className='pet1 swiper-slide'>
                  <VisualPetRoom />
                </li>
                <li className='pet2 swiper-slide'>
                  <VisualPetWorld />
                </li>
                <li className='pet3 swiper-slide'>
                  <VisualPetCrew />
                </li>
                <li className='pet4 swiper-slide'>
                  <VisualPetDiary />
                </li>
                <li className='pet5 swiper-slide'>
                  <VisualPetPal />
                </li>
              </ul>
              <div className='swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal swiper-pagination-visual'></div>
              <div className={`decoration ${mobileBullet}`} ref={decoRef}>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
            <div className='num'>
              <span className='count'>{countNum}</span> / 5
            </div>
          </div>
        </article>
        <article>
          <div className='part part1'>
            <Part1 />
          </div>
          <div className='part part2'>
            <Part2 />
          </div>
          <div className='part part3'>
            <Part3 />
          </div>
          <div className='part part4'>
            <Part4 />
          </div>
          <div className='part part5' ref={part5Ref}>
            <Part5 />
          </div>
          <div className='part hiing'>
            <Hiing />
          </div>
          <div className="part part6" ref={floatingLinkRef}>
            <Part6AppLinkInfo />
            {/* <div className={`app ${quickApp ? "hide" : ""}`}>
              <a href='#appLink' onClick={appDownLoad}>
                {
                  myLanguage === KOR ? (
                    <>
                      <img src='/images/sub/ic_app01.png' alt='bigglz app' />
                    </>
                  ) : myLanguage === ENG ? (
                    <>
                      <img src='/images/eng/eng_ic_app01.png' alt='bigglz app' />
                    </>
                  ) : null
                }
              </a>
            </div> */}
          </div>
        </article>
      </section>
    </>
  );
};

export default MainIndex;
