import React, { useContext } from "react";
import { outLinks } from "../../../../routes/routes";
import { BigglzContext } from "../../../../utils/contextProvider";
import { ENG, KOR } from "../../../../layout/layout";

export const BigglzRecruitTit = () => {
  const { myLanguage } = useContext(BigglzContext);

  return (
    <>
      <h2>
        {
          myLanguage === KOR ? (
            <>
              <span>Bigglz’s</span>를 소개할게요.
            </>
          ) : myLanguage === ENG ? (
            <>
              An introduction to <span>Bigglz’s</span>
            </>
          ) : null
        }
      </h2>
    </>
  )
}

export const BigglzIntroduce1 = () => {
  const { myLanguage } = useContext(BigglzContext);

  return (
    <>
      <h3>
        {
          myLanguage === KOR ? (
            <>
              우리는<br />
              모두 다른 <strong>취향</strong>과 <strong>개성</strong>을 존중해요.
            </>
          ) : myLanguage === ENG ? (
            <>
              We respect all
              <span> <strong>tastes</strong> and <strong>personalities</strong>.</span>
            </>
          ) : null
        }
      </h3>
      <div className="txt-intro">
        {
          myLanguage === KOR ? (
            <>
              서로를 진심으로 존중할줄 알아야, 함께 할 때 좋은 퍼포먼스가 나온다고 생각해요.
              <span>존중과 배려를 바탕으로 소통하다보니 구성원들끼리 끈끈하고 긴밀하게 움직이고 있어요.</span>
              <span>서로의 개성을 존중하고 협력하여 차별화된 서비스를 만들어 나가고 있어요.</span>
            </>
          ) : myLanguage === ENG ? (
            <>
              We believe that good performance comes when we truly respect each other.
              <span>Because we communicate based on respect and consideration, members work closely with each other. We respect each other's individuality and cooperate with</span>
              <span>each other to create World class services.</span>
            </>
          ) : null
        }
      </div>
      <div className="photo-area">
        <ul>
          <li>
            <figure>
              <img src="/images/sub/img_recruit_intro1.png" alt="" />
            </figure>
          </li>
          <li>
            <figure>
              <img src="/images/sub/img_recruit_intro2.png" alt="" />
            </figure>
          </li>
        </ul>
      </div>
    </>
  );
}

export const BigglzIntroduce2 = () => {
  const { myLanguage } = useContext(BigglzContext);

  return (
    <>
      <h3>
        {
          myLanguage === KOR ? (
            <>
              <span>
                모두의 꿈과 아이디어를 모아
              </span>
              <span>
                <strong>더 나은 미래 </strong>를 만들어 나가고 있어요.
              </span>
            </>
          ) : myLanguage === ENG ? (
            <>
              <span>
                We are creating a <strong>better future </strong>
              </span>
              <span>
                by Embracing everyone's dreams and ideas.
              </span>
            </>
          ) : null
        }
      </h3>
      <div className="txt-intro">
        {
          myLanguage === KOR ? (
            <>
              1+1=2가 아닌 1+1=200을 만들기 위해 모두의 꿈과 성장을 응원해요.
              <span>함께 성장할 수 있게 늘 고민하고 같이 만들어나가는 가치를 추구해요.</span>
            </>
          ) : myLanguage === ENG ? (
            <>
              We support Our team's dreams and growth to make 1+1=200, not 1+1=2.
              <span>We are always finding ways to grow and generate value through being together.</span>
            </>
          ) : null
        }
      </div>
      <div className="photo-area">
        <ul>
          <li>
            <figure>
              <img src="/images/sub/img_recruit_intro3.png" alt="" />
            </figure>
          </li>
          <li>
            <figure>
              <img src="/images/sub/img_recruit_intro4.png" alt="" />
            </figure>
          </li>
        </ul>
      </div>
    </>
  );
}

export const BigglzIntroduce3 = () => {
  const { myLanguage } = useContext(BigglzContext);

  return (
    <>
      <h3>
        {
          myLanguage === KOR ? (
            <>
              Who is Bigglz’s ?
            </>
          ) : myLanguage === ENG ? (
            <>
              Who is Bigglz’s ?
            </>
          ) : null
        }
      </h3>
      <div className="txt-intro">
        {
          myLanguage === KOR ? (
            <>
              #인성(Mind-set) #성장(Build-up) <span>#열정(Passion) #애사심(Royalty)</span>
            </>
          ) : myLanguage === ENG ? (
            <>
              #Mind-set #Build-up #Passion #Royalty
            </>
          ) : null
        }
      </div>
      <div className="photo">
        <img src="/images/sub/bg_resolution.png" alt="bigglz character" />
      </div>
      <ul className="resolution-list">
        {
          myLanguage === KOR ? (
            <>
              <li>“안 된다”는 마음이 아닌, “어떻게하면 되게할까?”를 고민하고 솔루션을 제안하는 사람</li>
              <li>자신의 업무에 자부심과 자긍심을 가지고, 매 순간 열정적으로 최선을 다하는 사람</li>
              <li>주변을 챙기는 마음, ‘서로’의 가치를 이해하고 소중히 여기는 사람</li>
              <li>능동적 마인드로 스스로 빠르게 성장해 나가는 사람</li>
              <li>잘못을 인정할 줄 알고, 이를 기반으로 더 빠른 성장을 하는 사람</li>
              <li>긍정적 마인드로 선한 에너지를 전달하는 사람</li>
              <li>목적과 근거를 기반으로 명철한 판단을 내리고 설득할 줄 아는 사람</li>
              <li>올바른 방향과 빠른 발전에 있어, 능동적으로 경영진과 함께 고민하고 꿈을 꾸는 사람</li>
            </>
          ) : myLanguage === ENG ? (
            <>
              <li>A person who thinks about “How can I make it work?” and proposes a solution, rather than thinking “I can’t do it.”</li>
              <li>A person who takes pride in their work and passionately does his or her best at every moment.</li>
              <li>People who care about those around them, who understand and cherish the value of ‘being together’</li>
              <li>A person who grows quickly on his own with a proactive mindset</li>
              <li>A person who knows how to admit mistakes and grows faster based on this</li>
              <li>A person who delivers good, positive energy</li>
              <li>A person who can make clear decisions and persuade others based on data and evidence</li>
              <li>A person who actively thinks and dreams about the right direction and rapid development</li>
            </>
          ) : null
        }
      </ul>
    </>
  );
}

export const RecruitProcedure = () => {
  const { myLanguage } = useContext(BigglzContext);

  return (
    <>
      {
        myLanguage === KOR ? (
          <>
            <h3>채용절차</h3>
            <ol className='process'>
              <li>
                <div className="num">01</div>
                서류
              </li>
              <li>
                <div className="num">02</div>
                면접 <span>실무 및 경영진</span>
              </li>
              <li>
                <div className="num">03</div>
                최종합격
              </li>
            </ol>
          </>
        ) : myLanguage === ENG ? (
          <>
              <h3>Recruitment procedure</h3>
              <ol className='process'>
                <li>
                  <div className="num">01</div>
                  Resume
                  <span>screening</span>
                </li>
                <li>
                  <div className="num">02</div>
                  Interview
                  <span>screening</span>
                </li>
                <li>
                  <div className="num">03</div>
                  Final
                  <span>acceptance</span>
                </li>
              </ol>
            </>
        ) : null
      }
    </>
  );
}

export const RecruitRegister = () => {
  const { myLanguage } = useContext(BigglzContext);

  return (
    <>
      {
        myLanguage === KOR ? (
          <>
            <h3>지원방법</h3>
            <ul className='mananer'>
              <li>
                <div className='how-to'>
                  <div className='num'>1</div>
                  <div className='tit'>
                    직접 지원하기 &#40;이메일 접수&#41;
                    <span>admin@bigglz.com</span>
                  </div>
                </div>
                <dl className='submit'>
                  <dt>제출서류</dt>
                  <dd>
                    1. 이력서 <span>자유 형식, a4 1장이내 권장</span>
                  </dd>
                  <dd>
                    2. 포트폴리오
                    <span>본인을 잘 표현할 수 있는 PDF 또는 링크를 첨부</span>
                  </dd>
                </dl>
              </li>
              <li>
                <div className='how-to'>
                  <div className='num'>2</div>
                  <div className='tit'>
                    <a href={outLinks.wanted} target="_blank" rel="noopener noreferrer" className="btn-link">
                      원티드
                    </a>
                    를 통해 지원하기
                  </div>
                </div>
              </li>
            </ul>
          </>
        ) : myLanguage === ENG ? (
          <>
            <h3>How to apply</h3>
            <ul className='mananer'>
              <li>
                <div className='how-to'>
                  <div className='num'>1</div>
                  <div className='tit'>
                    Applying by yourself &#40;email register&#41;
                    <span>admin@bigglz.com</span>
                  </div>
                </div>
                <dl className='submit'>
                  <dt>Required document</dt>
                  <dd>
                    1. Resume <span>Free form, within 1sheet recommended</span>
                  </dd>
                  <dd>
                    2. Portfolio
                    <span>Attach pdf file or link</span>
                  </dd>
                </dl>
              </li>
              <li>
                <div className='how-to'>
                  <div className='num'>2</div>
                  <div className='tit'>
                    <a href={outLinks.wanted} target="_blank" rel="noopener noreferrer" className="btn-link">
                      "Wanted"
                    </a>
                    Support through Wanted
                  </div>
                </div>
              </li>
            </ul>
          </>
        ) : null
      }
    </>
  );
}