import Main from "../components/page/main/index";
import About from "../components/page/about/index";
import Service from "../components/page/service/index";
import News from "../components/page/news/index";
import Recruit from "../components/page/recruit/index";
import Character from "../components/page/character/index";
import Persona from "../components/page/persona/index";
import AUTOMATICOMMENT from "../components/page/automaticComment/index";
import CONTACT from "../components/page/contact/index";

const HOME = '/';
const ABOUT = '/About';
const SERVICE = '/Service';
const NEWS = '/News';
const RECRUIT = '/Recruit';
const CHARACTER = '/Character';
const PERSONA = '/Persona';
const AutomaticComment = "/AutomaticComment";
const Contact = "/Contact";

export const routes = {
  home: HOME,
  about: ABOUT,
  service: SERVICE,
  news: NEWS,
  recruit: RECRUIT,
  character: CHARACTER,
  persona: PERSONA,
  automaticComment: AutomaticComment,
  contact: Contact,
};

export const components = {
  home: Main,
  about: About,
  service: Service,
  news: News,
  recruit: Recruit,
  character: Character,
  persona: Persona,
  automaticComment: AUTOMATICOMMENT,
  contact: CONTACT
};

export const outLinks = {
  bigglz: "https://www.bigglz.com",
  bigglzInsta: "https://www.instagram.com/bigglz_official/",
  bigglzPetpal: "https://www.instagram.com/petpal_five/",
  gallery: "https://www.artgggallery.com",
  ggisland: "https://www.instagram.com/ggisland_official",
  ggPlacPath: "https://naver.me/xiXrhdmZ",
  wanted: "https://www.wanted.co.kr/company/21731?t=1647417881436",
  notion: "https://bigglz.notion.site/5459581985d54282ad9640ad83581ed4",
  appleStore: "https://apps.apple.com/kr/app/%EB%B9%84%EA%B8%80%EC%A6%88-%EA%B0%80%EC%83%81-%ED%8E%AB-%ED%9E%90%EB%A7%81-%EC%95%B1-%EB%B0%98%EB%A0%A4%EB%8F%99%EB%AC%BC-%EC%BA%90%EB%A6%AD%ED%84%B0-%EB%A9%94%ED%83%80%EB%B2%84%EC%8A%A4/id1585622161",
  googlePlay: "https://play.google.com/store/apps/details?id=com.bigglz.BigglzApp",
  mbti: "https://pet-event.bigglz.com/mbti",
  hiingAppStore: "https://apps.apple.com/kr/app/%ED%95%98%EC%9E%89-%EA%B3%A0%EB%AF%BC%EC%83%81%EB%8B%B4-%ED%9E%90%EB%A7%81-%EB%8C%80%ED%99%94-ai-%EC%B1%84%ED%8C%85/id6476132969",
  hiingGoogleStore: "https://play.google.com/store/apps/details?id=co.hiing.hiing",
}
